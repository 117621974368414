import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom'
import UsersService from '../../../services/UsersService';
import ProjectsByUser from '../../Projects/ProjectsByUser';
import Avatar from './../Avatar';
import Loader from "react-loader-spinner";
import NotFound from '../../NotFound';
import FollowUnfollow from './FollowUnfollow';
import Menu from './Menu';
import Following from './Following';
import Followers from './Followers';
import {Helmet} from "react-helmet";
import StacksByUser from '../../Stacks/StacksByUser';
import PostsByUser from '../../Posts/PostsByUser';
import Linkify from 'react-linkify';

export default function UserProfile(props) {

    const [user, setUser] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState ('feed');
    
    const location = useLocation();

    useEffect( () => {
        const loadData = async() => {        
            switch (location.pathname) {
                case ('/@' + props.username):
                    setCategory('feed');
                    break;
                case ('/@' + props.username + '/projects'):
                    setCategory('projects');
                    break;
                case ('/@' + props.username + '/stack'):
                    setCategory('stack');
                    break;
                case ('/@' + props.username + '/followers'):
                    setCategory('followers');
                    break;
                case ('/@' + props.username + '/following'):
                    setCategory('following');
                    break;
            }

            const ret  = await UsersService.getUser(props.username);
            setUser(ret);
            setLoading(false);
        }
        loadData();

    }, [location.pathname]); 
    
    if (!loading) 
    {
        if (!user){
            // user does not exist so show 404
            return <NotFound></NotFound>;
        }
        return (
            <>
            <Helmet>
                <title>{user.name && user.name != 'null' && user.name !='undefined'? user.name: user.username} | Make.rs</title>
                <meta name="description" content={user.bio && user.bio != 'undefined' ? user.bio : "The place where makers show the world what they've made"} />
            </Helmet>

            <div className='container mx-auto text-center'>
                {/****************** */}

                {/* 
                <div className='flex space-x-2'>
                    <div className='flex-1'>
                        <div className='float-right'>
                            <Avatar src={user.avatar && user.avatar != 'undefined'? 'https://ik.imagekit.io/makers/avatars/' + user.avatar : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=200'} size='w-28 h-28'  />
                        </div>
                    </div>

                    <div className='flex-1'>
                        <div className='float-left text-left'>
                            <p className='font-bold text-black text-2xl '>{user.name} <FollowUnfollow userId={user.id} username={username} /> </p>  
                            <p className='text-gray-500 text-lg'>@{user.username}</p>

                    
                    {user.location && user.location != 'null' && user.location != 'undefined'? <p className='text-sm mt-2 flex  text-gray-500'><svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>{user.location}</p>: ''} 

                        </div>

                    </div>


                
                </div>

                <div className=' w-full text-center'>
                    {user.bio && user.bio != 'null' && user.bio != 'undefined' ? <p className='text-md sm:text-lg mt-2'>{user.bio}</p> : ''}

                    </div>

                */}
                {/****************** */}


                <div>
                    <Avatar src={user.avatar && user.avatar != 'undefined'? 'https://ik.imagekit.io/makers/avatars/' + user.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=200'} size='w-28 h-28'  />
                </div>

                {/* full name */}
                
                {user.name && user.name != 'undefined' ?
                    <div className='flex justify-center'>
                        <div className='flex-1'></div>
                        <div><p className='font-bold text-black text-2xl '>{user.name}</p>  </div>
                        <FollowUnfollow userId={user.id} username={props.username} /> 
                    </div>
                : ''
                }

                <div className='text-center'>
                    {/* 
                    {user.enableContact ? <a href={'mailto:' +  user.email} className='h-10 cursor-pointer text-xs text-center font-bold border-2 border-black rounded-md shadow-blue-300 pt-3 px-2 ml-5 hover:bg-blue-300 focus:outline-none'>CONTACT</a> : ''}
                    */}
                    
                    <div className='flex justify-center'>
                        {!user.name || user.name === 'undefined' ? <div className='flex-1'></div> : '' }
                        <p className='text-gray-500 text-lg'>@{user.username}</p>
                        {!user.name || user.name === 'undefined' ? <FollowUnfollow userId={user.id} username={props.username} /> : '' }

                    </div>
                    {user.bio && user.bio != 'null' && user.bio != 'undefined' ? 
                        <p className='text-md sm:text-lg mt-2'>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" className='underline text-blue-600 hover:text-blue-800 focus:outline-none' href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}>{user.bio}</Linkify>

                        </p> 
                        : ''
                    }
                    
                    {user.location && user.location != 'null' && user.location != 'undefined'? <p className='text-sm mt-2 flex justify-center text-gray-500'><svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>{user.location}</p>: ''}
                </div>


            </div>

            
            <div className='container mt-14 sm:mt-4 sm:mx-auto flex justify-center'>
                <Menu username={user.username} category={category}/>    

            </div>
            
            <br /><br /><br />            
            {category == 'feed' ? <PostsByUser userId={user.id} key={user.id}/> : <></>}
            {category == 'projects' ? <ProjectsByUser userId={user.id} key={user.id}/> : <></>}
            {category == 'stack' ? <StacksByUser userId={user.id} key={user.id}/> : <></>} 
            {category == 'following' ? <Following userId={user.id} key={user.id}/> : <></>}
            {category == 'followers' ? <Followers userId={user.id} key={user.id}/> : <></>}
                

            </>
        );
    }
    else
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
}
