import axios from "axios";
import authHeader from "./auth-header";
import Cookies from 'js-cookie';


class AuthenticationService {

  
  getMagicLink = async (email) => {
    return axios.post(process.env.REACT_APP_MAKERS_API + "/auth/get-magic-link", {email})
      .then(() => {
        // check if response.data.accessToken is valid??
        return "OK";
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  signIn = async (token) => {
    console.log('signing in with token magic: ' + token);
    return axios.post(process.env.REACT_APP_MAKERS_API + "/auth/sign-in", null, {headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
        // check if response.data.accessToken is valid??
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  signUp = (email, username) => {
    return axios.post(process.env.REACT_APP_MAKERS_API + "/auth/sign-up", {email, username})
      .then(response => {
        // check if response.data.accessToken is valid??
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
}

  signOut() {
    Cookies.remove('maker');
    //localStorage.removeItem("user");
  }

  getCurrentUser() {
    try {
      //return JSON.parse(localStorage.getItem('user'));;
      return JSON.parse(Cookies.get('maker'));

    } catch (err) {
      //console.log("cannot retrieve user from localstorage")
      return null;
    }
  }


  saveCurrentUser(data) {
    Cookies.set('maker', JSON.stringify(data), { expires: 30 });

    //localStorage.setItem("user", JSON.stringify(data));

  }
  checkSecure(){
    return axios.get(process.env.REACT_APP_MAKERS_API + "/secure",  { headers: authHeader()})
    .then ( (res) => {
        console.log('check secure' + res.data);
    });

  }

  checkUsername(username) {
    return axios.post(process.env.REACT_APP_MAKERS_API + "/auth/check-username",  { username})
    .then ( (res) => {
        return res.data;
    });

  }

  checkEmail(email) {
    return axios.post(process.env.REACT_APP_MAKERS_API + "/auth/check-email",  { email})
    .then ( (res) => {
        return res.data;
    });

  }

}

export default new AuthenticationService();
