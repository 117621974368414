import React, { useState, useEffect } from 'react';
import {useNavigate, Link} from 'react-router-dom';
import ProjectsService from '../../services/ProjectsService';
import PostsService from '../../services/PostsService';
import { useLocation, useParams } from 'react-router-dom';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import PostCard from '../Posts/PostCard';
import CommentList from '../Comments/CommentList'; 
var dayjs = require ('dayjs');
var utc = require('dayjs/plugin/utc')
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(relativeTime)


export default function ProjectDetail(props) {
    const history = useNavigate()
    const [project, setProject] = useState(null);
    const [updates, setUpdates] = useState([]);
    const [showComments, setShowComments] = useState(false);
    const [commentsCount, setCommentsCount] = useState(0);
    const [mounted, setMounted] = useState(false);
  
    const incrementCommentsCount = () => {
        setCommentsCount(commentsCount+1);
    }
  
    
    const location = useLocation()
    const {slug } = useParams();
 
    const goBack = () => {
        if (document.referrer && document.referrer.length > 0 && document.referrer.startsWith(process.env.REACT_APP_SITE_URL)) {
            history.goBack();
        }
        else {
            window.location = process.env.REACT_APP_SITE_URL;

        }
      }
      

    const getProjectUpdates = async (projectId) => {
        const updatesList = await PostsService.getPostsByProjectId(projectId);
        if (updatesList) {
            setUpdates(updatesList);
        }
    }

    useEffect( () => {
        const loadData = async () => {
            // parse the slug
            const slugSplit = slug.split('-');
            const projectId = slugSplit[0];
            const ret  = await ProjectsService.getProject(projectId);
            if (ret){
                setProject(ret); 
                setCommentsCount(ret.comments ? ret.comments : 0);
            }

            await getProjectUpdates(projectId);

            setMounted(true);
        }
        loadData();
    }, []); 


    if (mounted && project) {
        return (
            <>
            <Helmet>
                <title>{project.name} | Make.rs</title>
            </Helmet>


            <div className='bg-white rounded-xl shadow-md container mx-auto sm:w-2/3 py-4 relative'>
                {project.url && project.url !== 'undefined' ? 
                    <a href={project.url + '?ref=make.rs'} target='_blank' className={"flex flex-row justify-center font-bold text-center text-gray-700 text-sm rounded-tr-md rounded-bl-md py-2 px-2 bg-yellowLight  hover:bg-yellow focus:outline-none absolute right-0 top-0 mr-0"}>
                    Visit site 
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>    
                        
                    </a> 
                : ''}

                <div className='text-center'>
                    <p className='font-bold text-black text-4xl'>{project.name} </p>  

                    {/*made by user*/}
                    <div className="flex justify-center flex-shrink-0 h-6 my-2 ">                        
                        <Link to={'/@' + project.username} className='flex items-center'>
                        <span className=" mr-2 h-full text-black text-lg">Made by</span>                              
                        <img className="rounded-full float-left h-10 " src={project.avatar && project.avatar != 'undefined' ? "https://ik.imagekit.io/makers/avatars/" + project.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} /> <span className="inline-block ml-2 h-full text-gray-500 text-lg font-bold">@{project.username}</span>
                        </Link>
                    </div>

                </div>

                <div className='container mx-auto sm:flex mt-10 justify-center'>
       
                    <div className='px-4  w-full'>

                        <div className='text-black text-lg whitespace-pre-line mt-10 inline-block w-full'>
                        {project.description}

                        {project.images ? <a href={"https://ik.imagekit.io/makers/projects/" + project.images} target='_blank'><img src={"https://ik.imagekit.io/makers/projects/" + project.images + '?tr=w-300'} className='rounded-xl mx-auto w-auto ml-0 mb-4'/></a> : ''}

                        </div>
                        <br />
                        <div className=' flex'>
                        
                        <button onClick={() => { setShowComments(!showComments)}}  className={"flex text-gray-400 font-bold text-center text-sm  py-2 hover:text-gray-800 focus:outline-none"}>
                            <svg className='h-6 w-6 fill-current mr-1' viewBox="0 -45 512 511"  xmlns="http://www.w3.org/2000/svg"><path d="m407 .5h-302c-57.898438 0-105 47.101562-105 105v162.171875c0 46.199219 30.332031 86.4375 74.285156 99.316406l50.710938 50.714844c2.816406 2.8125 6.628906 4.394531 10.609375 4.394531 3.976562 0 7.792969-1.582031 10.605469-4.394531l46.519531-46.523437h214.269531c57.898438 0 105-47.101563 105-105v-160.679688c0-57.898438-47.101562-105-105-105zm75 265.679688c0 41.355468-33.644531 75-75 75h-220.480469c-3.976562 0-7.792969 1.582031-10.605469 4.394531l-40.308593 40.308593-42.929688-42.929687c-1.925781-1.925781-4.339843-3.292969-6.984375-3.949219-32.789062-8.160156-55.691406-37.492187-55.691406-71.332031v-162.171875c0-41.355469 33.644531-75 75-75h302c41.355469 0 75 33.644531 75 75zm0 0"/><path d="m351.242188 144.328125h-190.484376c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15h190.484376c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15zm0 0"/><path d="m351.242188 197.351562h-190.484376c-8.285156 0-15 6.714844-15 15 0 8.285157 6.714844 15 15 15h190.484376c8.285156 0 15-6.714843 15-15 0-8.285156-6.714844-15-15-15zm0 0"/></svg>
                            Comments ({commentsCount})</button>
                        </div>
                    </div>
                </div>

                {/* Comments */}
                {showComments ? 
                    <div className="  mt-10 flex w-full">      
                        <div className='w-full '>
                            <div className='comments bg-white rounded-xl px-4 py-4'>
                                <CommentList incrementCommentsCount={incrementCommentsCount} id={project.id} userId={project.userId} cardType='project'/> 
                            </div>
                        </div>
                    </div>
                    : ''
                }

            </div>
            
            {/* Project Updates */}
            {updates.length > 0 ?
            <div className='container mx-auto w-full sm:w-2/3 flex mt-10'>
                <div className='pb-2 w-full'>
                    <div className='text-2xl text-center mb-10'><span className='font-bold text-gray-700'>Latest updates</span></div> 
                    {updates.map(function(update, index){
                    return (
                        <React.Fragment key={index}>
                        {index > 0 ? 
                            <div className="h-24 flex flex-col justify-center items-center" >
                                <div className='h-8 w-0.5 bg-white' aria-hidden="true"></div>
                                <div className='h-8 text-blue-400 bg-white  border-2 border-white rounded-xl px-2 '><span className='inline-block align-middle'>{dayjs.utc(update.createdAt).format('D MMMM YYYY')}</span></div>
                                <div className='h-8 w-0.5 bg-white' aria-hidden="true"></div>
                            </div> 
                            : 
                            <div className="h-16 flex flex-col justify-center items-center" >                            
                            <div className='h-8 text-blue-400 bg-white border-2 border-white rounded-xl px-2'><span className='inline-block align-middle'>{dayjs.utc(update.createdAt).format('D MMMM YYYY')}</span></div>
                            <div className='h-8 w-0.5 bg-white' aria-hidden="true"></div>
                            </div> 
                                
                        }
                        <PostCard key={index} index={index} noBottomSpace={true} id={update.id} hideActions={true} images={update.images} fullName={update.fullName} username={update.username} avatar={update.avatar} bio={update.bio} message={update.message} projectId={update.projectId} projectName={update.projectName} commentsCount={update.comments} claps={update.claps} createdAt={update.createdAt}/>
                        </React.Fragment>
                        )
                    }
                    )}

                </div>
            </div>
            : ''}

            </>
        )
    }
    else {
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" /></div>);
    }
}