import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";




export default function Storyteller(props) {

    return (
        <>
        <Helmet>
        <title>The story behind your project | Make.rs</title>
        <meta name="description" content="The story behind your project" />
        <body className="home-bg" />
        </Helmet>           

        <div className='container mx-auto mb-10'>        
            <p className="text-center text-black text-4xl sm:text-6xl font-bold">Your project has a beautiful story 😍</p>
            <p className="text-center text-black text-2xl  mt-8">Your achievements are nicely organized chronologically to show your journey. Visualize everything from the last update back to when you started. </p>
            <div className='flex justify-center mt-6'>
            <img src='https://ik.imagekit.io/makers/images/project_updates.png?tr=w-800' />
            </div>
            

            <br /><br />
        </div>
        <div className=' bg-emerald-200 py-20'>
            <div className='container mx-auto'>
                <p className="text-center  text-black text-4xl sm:text-6xl font-bold">Easy to access, easy to share 🪄</p>
                <p className="text-center text-black  text-xl sm:text-2xl mt-8">Your project page has a unique URL that can be accessed by anyone and from anywhere.</p>
                <p className="text-center  text-black  text-xl sm:text-2xl mt-8">Use it to communicate new releases to your users, or to showcase your work.</p>
                <p className="text-center  text-blue-700 text-xl sm:text-2xl mt-8">https://make.rs/project/<span className='font-bold'>{'{project slug}'}</span></p>
                <p className="text-center  text-black font-bold text-xl sm:text-2xl mt-8">Some projects from our makers:</p>
                <div className='flex space-x-6 justify-center text-xl font-bold mt-8 flex-wrap'>
                    <Link to={"/project/255-kairo"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>Kairo</Link>
                    <Link to={"/project/209-highscoredomains"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>HIGHSCORE.domains</Link>
                    <Link to={"/project/261-logology"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>Logology</Link>
                    <Link to={"/project/229-embedws-create-websites-from-a-gsheetairtable"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>EmbedWS | Create websites from a gsheet/airtable</Link>
                    <Link to={"/project/272-razum"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>Razum</Link>
                    <Link to={"/project/248-the-lesson-club"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>The Lesson Club 🦉</Link>
                    <Link to={"/project/241-sotent"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>Sotent</Link>
                    <Link to={"/project/294-no-sugar-today"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>No Sugar Today</Link>
                    <Link to={"/project/297-stym"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>Stym</Link>
                    <Link to={"/project/296-pixomoji"} className='bg-yellowLight mb-2 px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>Pixomoji</Link>

                
                
                </div>
            </div>
            <br /><br />
        </div>
        <div className=' bg-purple-200 py-20'>
            <div className='container mx-auto'>
            <p className="text-center text-black text-4xl sm:text-6xl font-bold">Not everyone is on Twitter 😉</p>
            <p className="text-center text-black  text-xl sm:text-2xl mt-8">I made Make.rs so that your progress can be followed also by people who are not on Twitter or on other social networks. </p>
            <p className="text-center text-black  text-xl sm:text-2xl mt-8">Plus, many times, people on Twitter miss your updates because their feed is very busy and oddly sorted. Your updates quickly slip away. With Make.rs your project updates are nicely organized & easily accessible at any time.</p>

            </div>


        </div>

        </>
    );

}
