import React, { useState, useEffect } from 'react';
import UsersService from '../../../services/UsersService';
import {Link} from 'react-router-dom';
import {useAuth} from '../../AuthContext';
import Loader from "react-loader-spinner";

export default function Following(props){
    const { authToken } = useAuth();
    const [users, setUsers] = useState ([]);
    const [mounted, setMounted] = useState(false);

    useEffect (  () => {
      const loadData = async() => {      
        const ret = await UsersService.getFollowing(props.userId);
        if (ret) {
            setUsers(ret);
        }
        setMounted(true);
      }
      loadData();

      return () => {
        setUsers([])
      }

    }, []);

    if (!mounted)
      return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else 
    return (
        <div className='container mx-auto flex flex-wrap space-x-4 justify-center'>
              {users.length == 0 ? <p className='text-gray-400 text-xl mt-10'>{authToken && authToken.id == props.userId ? "You aren't following any maker yet.": "This maker isn't following anyone yet."}</p>: ''}
                {users.map(function(user, index){
                  return (
                    <div key={index} className='flex-shrink-0 mb-4'>
                      <Link key={index} to={'/@' + user.username}>
                      <img key={index} src={user.avatar && user.avatar != 'undefined'? 'https://ik.imagekit.io/makers/avatars/' + user.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} className='w-20 h-20 rounded-full mx-auto'  />
                      <p className='text-gray-500 text-center'>@{user.username}</p>
                      </Link>
                    </div>
                    )
                  }
                )}


        </div>
    );
}