import axios from "axios";
import AuthenticationService from '../services/AuthenticationService';


class ProjectsService {

  // create new project
  newProject = async(name, description, url, image, forSale) => {
    const user = AuthenticationService.getCurrentUser();
    let fd = new FormData();
    if (image) fd.append('image', image, image.name);
    fd.append('name', name);
    fd.append('description', description);
    fd.append('url', url);
    fd.append('forSale', forSale);


    const ret = await axios.post(process.env.REACT_APP_MAKERS_API + "/projects/", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}});
    return ret.data;
    /*
    return axios.post(process.env.REACT_APP_MAKERS_API + "/projects/", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          return res.data;

      });*/
  }

  // update project
  updateProject = async(id, name, description, url, image, forSale) => {
    const user = AuthenticationService.getCurrentUser();
    let fd = new FormData();
    if (image) fd.append('image', image, image.name);
    fd.append('name', name);
    fd.append('description', description);
    fd.append('url', url);
    fd.append('forSale', forSale);


    return axios.patch(process.env.REACT_APP_MAKERS_API + "/projects/" + id, fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }

  getProject = async (id) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/projects/" + id)
      .then ( (res) => {
          return res.data;
      });
  }

  getProjects = async (offset) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/projects/everyone/' + offset)
    .then(res => {
      const projects = res.data.map(obj => ({id: obj.id, name: obj.name, description: obj.description, images: obj.images, url: obj.url, userId:obj.userId, fullName: obj.fullName, forSale: obj.forSale, createdAt: obj.createdAt, username: obj.username, avatar: obj.avatar, bio: obj.bio, claps:obj.claps, comments: obj.comments}));
      return projects;
    });
  }

  getNewProjects = async () => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/projects/new/')
    .then(res => {
      const projects = res.data.map(obj => ({id: obj.id, name: obj.name, description: obj.description, images: obj.images, url: obj.url, forSale: obj.forSale, createdAt: obj.createdAt}));
      return projects;
    });
  }

  getProjectsFollowing = async (offset) => {
    const user = AuthenticationService.getCurrentUser();
    if (!user) return [];
    return axios.get(process.env.REACT_APP_MAKERS_API + '/projects/following/' + offset, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`} })
    .then(res => {
      const projects = res.data.map(obj => ({id: obj.id, name: obj.name, description: obj.description, images: obj.images, url: obj.url, userId:obj.userId, fullName: obj.fullName, forSale: obj.forSale, username: obj.username, avatar: obj.avatar, bio: obj.bio}));
      return projects;
    });
  }

  getProjectsForsale = async (offset) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/projects/forsale/' + offset)
    .then(res => {
      const projects = res.data.map(obj => ({id: obj.id, name: obj.name, description: obj.description, images: obj.images, url: obj.url, userId:obj.userId, fullName: obj.fullName, forSale: obj.forSale, username: obj.username, avatar: obj.avatar, bio: obj.bio, claps:obj.claps, comments:obj.comments, feedType:'project'}));
      return projects;
    });
  }

  getProjectsByUserId = async (userId) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/projects/byuser/' + userId)
    .then(res => {
      const projects = res.data.map(obj => ({id: obj.id, name: obj.name, description: obj.description, images: obj.images, url: obj.url, userId:obj.userId, fullName: obj.fullName, forSale: obj.forSale, username: obj.username, avatar: obj.avatar, bio: obj.bio, claps:obj.claps, comments:obj.comments, feedType:'project'}));
      return projects;
    });
  }

  // delete project
  deleteProject = async(id) => {
    const user = AuthenticationService.getCurrentUser();
    return axios.delete(process.env.REACT_APP_MAKERS_API + "/projects/" + id, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`} })
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }


}

export default new ProjectsService();
