import React from 'react';

export default function StackCard(props) {

  return (
    <>
    <div className='mx-2 my-2'>
      <img className='mx-auto' src={props.logo ? 'https://ik.imagekit.io/makers/techs/' + props.logo : 'https://api.faviconkit.com/' + props.domain + '/144' } style={{width: '50px'}}></img>
      <p className='text-center text-sm'>{props.name}</p>
    </div>
    </>
  );


}

