import axios from "axios";


class CommentsService {

  // create new project comment
  newProjectComment = async(accessToken, projectId, parentId, body) => {

    let fd = new FormData();
    fd.append('projectId', projectId);
    fd.append('parentId', parentId);
    fd.append('body', body);

    return axios.post(process.env.REACT_APP_MAKERS_API + "/comments/project", fd, { headers: {"Authorization" : `Bearer ${accessToken}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }

  // create new project comment
  newPostComment = async(accessToken, postId, parentId, body) => {

    let fd = new FormData();
    fd.append('postId', postId);
    fd.append('parentId', parentId);
    fd.append('body', body);

    return axios.post(process.env.REACT_APP_MAKERS_API + "/comments/post", fd, { headers: {"Authorization" : `Bearer ${accessToken}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }

  /*
  getComment = async (id) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/comments/" + id)
      .then ( (res) => {
          return res.data;
      });
  }*/

  getProjectComments = async (projectId) => {

    return axios.get(process.env.REACT_APP_MAKERS_API + '/comments/byproject/' + projectId)
    .then(res => {
      return res.data;
    });
  }

  getPostComments = async (postId) => {

    return axios.get(process.env.REACT_APP_MAKERS_API + '/comments/bypost/' + postId)
    .then(res => {
      return res.data;
    });
  }
}

export default new CommentsService();
