import axios from "axios";


class NotificationsService {


  seenNotifications(accessToken) {
    return axios.patch(process.env.REACT_APP_MAKERS_API + "/notifications", [], { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

  getNotifications(accessToken) {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/notifications", { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

}

export default new NotificationsService();
