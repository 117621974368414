//import Cookies from 'js-cookie';
import AuthenticationService from '../services/AuthenticationService';



export default function authHeader() {
    //const user = JSON.parse(localStorage.getItem('user'));
    const user = AuthenticationService.getCurrentUser();
    
    if (user && user.accessToken) {
      return { 'Authorization': `Bearer ${user.accessToken}` };
    } else {
      return {};
    }
  }
  