import React, { useState } from 'react';
import { Navigate, Link} from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService';
import {useAuth} from '../AuthContext';
import validator from 'validator';
import {Helmet} from "react-helmet";

export default function SignUp () {

  const [loggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const { setAuthToken } = useAuth();
  const [errors, setErrors] = useState({
    email : false,
    emailMessage: null,
    username: false, 
    usernameMessage: null   
  });


  const doValidate = async () => {
    let hasErrors = false;
    let errorsCheck  = [{
      email : false,
      username: false,
    }];

    let retUsername = null;
    if (validator.isEmpty(username) || !validator.isAlphanumeric(username) || ((retUsername =await AuthenticationService.checkUsername(username)) != 'OK')) {
      hasErrors = true;
      errorsCheck.username = true;
      if (retUsername == 'NOK') errorsCheck.usernameMessage = 'This username is taken';
    } 

    let retEmail = null;
    if (validator.isEmpty(email) || !validator.isEmail(email) || ((retEmail = await AuthenticationService.checkEmail(email)) != 'OK')) {
      hasErrors = true;
      errorsCheck.email = true;
      if (retEmail == 'NOK') errorsCheck.emailMessage = 'This email address already exists';
    } 

    setErrors(errorsCheck);
    return hasErrors;
  }
  
  const doSignUp = async (event) => {
    event.preventDefault();

    if (! (await doValidate())) {
      AuthenticationService
          .signUp(email, username)
          .then(
          (data) => {
            // check result.status == 200 ??
            setAuthToken(data);
            setLoggedIn(true);
            //this.context.setLoggedIn(true, this.state.email);
            //this.props.history.push('/profile');
          },
          error => {
            console.log("Signup error = { " + error.toString() + " }");
            setIsError(true);
          }
      );
    }
  }

  if (loggedIn) {
    return <Navigate to={"/welcome"} />;
  }

  return (<>
    <Helmet>
      <title>Make.rs | Sign up</title>
    </Helmet>   

    <div className="container mx-auto">
      <p className="text-center text-black text-4xl font-bold">Join the makers community</p>
      <p className="text-center text-black text-2xl mt-8">The registration is free.  We <span className='text-red-700'>❤️</span> all makers.</p>            

      <form method="post" onSubmit={doSignUp}>
      <div className="grid grid-cols-2 gap-2 md:gap-4 mt-10">

          <div className='text-right pt-1'>
            <label htmlFor='email' className='text-2xl font-bold'>Your email</label>
          </div>
          <div>
            <input type="text" name="email" id="email" placeholder="elon@tesla.com" onChange={e => { setEmail(e.target.value);}} className={"w-5/6 md:w-auto border-2 rounded-md py-2 px-2 md:px-4 focus:outline-none " + (errors.email  ? 'border-red-500': 'border-black')}/>
            {errors.emailMessage ? <><br /><span className='text-red-500 '>{errors.emailMessage}</span></> :<></>}
          </div>
          <div className='text-right pt-1'>
            <label htmlFor='username' className='text-2xl font-bold'>make.rs/@</label>
          </div>
          <div>
            <input type="text" name="username" id="username" placeholder="elon" onChange={e => { setUsername(e.target.value);}} className={"w-5/6 md:w-auto border-2 rounded-md py-2 px-2 md:px-4 focus:outline-none " + (errors.username ? 'border-red-500': 'border-black')}/>        
            {errors.usernameMessage ? <><br /><span className='text-red-500 '>{errors.usernameMessage}</span></> :<></>}
          </div>
      </div>
      <div className=" mt-10 md:text-center text-lg md:text-2xl">You don't need to remember another password. We will send you a magic link to sign in.</div>
      <div className="mt-10 md:text-center text-lg md:text-2xl italic">By signing up you agree to make.rs' <Link to='/terms' className='underline text-blue-600 hover:text-blue-800'>terms of use</Link>.</div>
      <div className='flex justify-center mt-4'>
            <button type="submit" className="text-center font-bold border-2 border-black rounded-md shadow-emerald-big py-2 px-4 hover:bg-emerald focus:outline-none">Create free account</button>
      </div>

      </form>


    </div>
    
</>    
    )
  
}
