import React , {useState, useEffect, useRef} from 'react';
import {components} from "react-select";
import AsyncSelect from "react-select/async";
import AddTech from './AddTech';
import StacksService from '../../services/StacksService';


export default function SelectTech(props) {
    const [query, setQuery] =useState(null);
    const [selected, setSelected] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [makingCall, setMakingCall] = useState(false);
    const [techChanged, setTechChanged] = useState(false);
    const selectRef = useRef(null);

    useEffect( () => {
      // first time, save defaults in selected list
      //console.log(props.defaults)
      setSelected(props.defaults);
    }, []);

    useEffect( () => {
      // first time, save defaults in selected list
      //console.log(query)
    }, [query]);


    const handleAddSelect = (tech) => {
      let items = selected;
      items.push (tech);
      setSelected(items);
    }

    const showSelectRef = () => {
      selectRef.current.focus();
    }

    const handleSelectChange = (selectedOptions) => {
        setSelected(selectedOptions);
        setTechChanged(true); // mark the change to show the save stack button
    };

    const loadOptions = () => {
      // throttle system in case the call is still running
      if (!makingCall) {
        setMakingCall(true);
        return fetch(`${process.env.REACT_APP_MAKERS_API}/stacks/querytechs?q=${query}`)
            .then((res) => {
              setMakingCall(false);
              return res.json();
               
            });
      }
     };

     const IconOption = (props) => (
      <components.Option {... props}>
        <div className='flex space-x-4 '>
          <img src={props.data.logo ? 'https://s3.eu-west-3.amazonaws.com/make.rs/techs/' + props.data.logo : 'https://api.faviconkit.com/' + props.data.domain + '/144'} className='w-6 h-6' /><span> {props.label}</span>
        </div>
      </components.Option>
    );

    const MultiValueLabel = (props) => (
      <components.MultiValueLabel {... props}>
        <div className='flex space-x-4'>
          <img src={props.data.logo ? 'https://s3.eu-west-3.amazonaws.com/make.rs/techs/' + props.data.logo : 'https://api.faviconkit.com/' + props.data.domain + '/144'} className='w-6 h-6' /><span> {props.data.name}</span>
        </div>
      </components.MultiValueLabel>
    );

    const NoOptionsMessage = (props) => (
        <components.NoOptionsMessage {... props}>
          {!query ? 'Type to search' : <><span>Don't find your tech?</span> <button onClick={() => {setAddNew(!addNew); selectRef.current.blur();}} className='underline text-blue-600 hover:text-blue-800'>Add it</button></>}          
        </components.NoOptionsMessage>
      );
  
    // save stack
    const  doSave = async (event) => {
      event.preventDefault();

      try {
          await StacksService.saveStack(selected); // need to pass the real group id
          console.log('Stack Saved');
      }
      catch (error) {
          console.log("Save stack fail: error = { " + error.toString() + " }");
          //setError({error: "An error has ocurred"});
      }

      props.setEditMode(false);
    }  

    return (
    <>
          <AsyncSelect
          ref={selectRef}
          /* cacheOptions */
          isMulti
          isSearchable
          autoFocus={false}
          loadOptions={loadOptions}
          defaultValue={props.defaults}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          onInputChange={(value) => setQuery(value)}
          /* noOptionsMessage={() => query ? 'No tech found': 'Type to search'}*/
          components={{NoOptionsMessage, MultiValueLabel,  Option: IconOption }}
          onChange={handleSelectChange}
      />

    {/* save stack button*/}
    {!addNew && techChanged? <button onClick={doSave} className="flex mx-auto justify-center whitespace-pre	text-center font-bold border-2 rounded-md border-black shadow-emerald mt-4 py-1 px-2 hover:bg-emerald focus:outline-none" >Save stack</button> : ''}

    {/* add new tech */}
    {addNew ? <AddTech setAddNew={setAddNew} setTechChanged={setTechChanged} handleAddSelect={handleAddSelect} showSelectRef={showSelectRef}/> : ''}

    </>
    );

}
