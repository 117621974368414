import React, { useState, useEffect } from 'react';
import PostsService from '../../services/PostsService';
import {useParams } from 'react-router-dom';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import PostCard from '../Posts/PostCard';


export default function ProjectDetail(props) {
    const [post, setPost] = useState(null);
    const [mounted, setMounted] = useState(false);
  
    const {slug } = useParams();
 
    useEffect( () => {
        const loadData = async () => {
            // parse the slug
            const slugSplit = slug.split('-');
            const postId = slugSplit[0];
            const ret  = await PostsService.getPost(postId);
            if (ret){
                setPost(ret); 
            }

            setMounted(true);
        }
        loadData();
    }, []); 


    if (mounted && post) {
        return (
            <>
            <Helmet>
                <title>Make.rs</title>
            </Helmet>

            <div className='container mx-auto sm:flex mt-16 justify-center'>
                <div className='w-full md:w-2/3'>

                <PostCard id={post.id} hideActions={true} images={post.images} fullName={post.fullName} username={post.username} avatar={post.avatar} bio={post.bio} message={post.message} projectId={post.projectId} projectName={post.projectName} commentsCount={post.comments} claps={post.claps} createdAt={post.createdAt}/>
                </div>
            </div>


            </>
        )
    }
    else {
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" /></div>);
    }
}