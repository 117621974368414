import React from 'react'
import {Link} from 'react-router-dom';

export default function ByUser(props) {

    return (
        <div className="inline-block h-6 my-2">            
            <Link to={'/@' + props.username} className='flex items-center'>
            <img className="rounded-full float-left h-6" src={props.avatar && props.avatar != 'undefined' ? "https://ik.imagekit.io/makers/avatars/tr:w-100/" + props.avatar: 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} /> <span className="inline-block ml-2 h-full text-gray-500 text-sm font-bold">@{props.username}</span>
            </Link>
	    </div>
    )
}