import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ProjectsService from '../../services/ProjectsService';
import {useAuth} from '../AuthContext';
import ProjectCard from './ProjectCard'
import validator from 'validator';
import Loader from "react-loader-spinner";

export default function AddProject (props) {
  const navigate = useNavigate();
  const id = props.id;
  const { authToken, setAuthToken } = useAuth();
  const [mounted, setMounted] = useState(false);

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [name, setName] = useState ('');
  const [desc, setDesc] = useState ('');
  const [url, setUrl] = useState (null);
  const [image, setImage] = useState (null);
  const [tempImage, setTempImage] = useState (null);
  const [forSale, setForSale] =useState(false);
  const [newImage, setNewImage] = useState (null);
  const [preview, setPreview] = useState(false);
  const [errors, setErrors] = useState({
    name : false,
    desc: false,
    url: false,
    
  });


  const handleNameChange = useCallback(e => {        
    setName(e.target.value)                          
  }, []);

  const handleDescChange = useCallback(e => {        
    setDesc(e.target.value)                          
  }, []);

  const handleUrlChange = useCallback(e => {        
    setUrl(e.target.value)                          
  }, []);

  const handleImageChange = useCallback(e => {        
    setNewImage(e.target.files[0]);
    setTempImage(URL.createObjectURL(e.target.files[0]));
  }, []);

  const handlePreviewClick = () => {
    setPreview(!preview);
  }

  useEffect(  () => {
    const loadData = async () => {
      if (id) {
        const ret = await ProjectsService.getProject(id);
        if (ret) {
          setName(ret.name);
          setDesc (ret.description);
          setUrl (ret.url);
          setImage(ret.images);
          setForSale(ret.forSale == true ? true: false);
        }
    
      }
      else {
        // reset session values
        setName('');
        setDesc('');
        setUrl(null);
        setImage(null);
        setTempImage(null);
        setForSale(false);
      }

      setMounted(true);
    }
    loadData();
    
  }, []);
  
  
  const  doSave = async (event) => {
    event.preventDefault();
    setSaveInProgress(true);

    // do validate
    let hasErrors = false;
    let errorsCheck  = [{
      name : false,
      desc: false,
      url: false,
    }];

    if (name===null || validator.isEmpty(name)) {
      hasErrors = true;
      errorsCheck.name = true;
    }
    if (desc===null || validator.isEmpty(desc)) {
      hasErrors = true;
      errorsCheck.desc = true;
    }

    if (url!= null && !validator.isURL(url, {require_protocol: true})) {
      hasErrors = true;
      errorsCheck.url = true;
    }


    setErrors(errorsCheck);
    // end validate

    if (!hasErrors){
      if  (id) {
        // update project
        try {
          await ProjectsService.updateProject(id, name, desc, url, newImage, forSale ? 1:0);
          //console.log('Project Updated');
          props.setOpenOverlay(false); // close the overlay
          navigate ('/@' + authToken.username + '/projects?ts=' + Date.now());
        }
        catch (error) {
          console.log("Update project fail: error = { " + error.toString() + " }");
          //setError({error: "An error has ocurred"});
        }

      }
      else {
        // create project
        try {
            await ProjectsService.newProject(name, desc, url, newImage, forSale ? 1:0);            
            //console.log('Project Saved');
            props.setOpenOverlay(false); // close the overlay
            navigate ('/@' + authToken.username + '/projects?ts=' + Date.now());
        }
        catch (error) {
          console.log("Save project fail: error = { " + error.toString() + " }");
          //setError({error: "An error has ocurred"});
        }
      }
    } // end hasErrors if
    setSaveInProgress(false);

  }  
  

  const doCancel = async (e) => {        
    e.preventDefault();
    navigate('/@' + authToken.username + '/projects');

  };
 
  if (!mounted) return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
  else
  return (
    <div className='w-full '>
      <div className='w-full'>
        <form method="post" className="w-full text-left"  >

          <div className="mb-6">
              <input id="name" onChange={handleNameChange} value={name ? name: ""} placeholder='Project name' className={'border-2 rounded-md p-2 w-full focus:outline-none ' + (errors.name ? 'border-red-500': 'border-black')}/>
          </div>

          <div className="mb-6">
              <textarea id="description" rows={5} onChange={handleDescChange} value={desc ? desc: ""} placeholder="Some tips on how to write a compelling description about your project:&#13;&#10;-Describe what the project is about/what problem does it solve... &#13;&#10;-How you made it...&#13;&#10;-What stack you have used...&#13;&#10;" className={'border-2 rounded-md p-2 w-full focus:outline-none ' + (errors.desc ? 'border-red-500': 'border-black')}/>
          </div>

          <div className="mb-6">
              <input id="url" onChange={handleUrlChange} value={url && url !='null' ? url: ""} placeholder="Link if there's any (optional)" className={'border-2 rounded-md p-2 w-full focus:outline-none ' + (errors.url ? 'border-red-500': 'border-black')}/>
          </div>

          <div className="mb-6">
              <label htmlFor='image' className='inline-flex cursor-pointer bg-white  border-2 border-black rounded-md shadow-yellow p-2 hover:bg-yellow focus:outline-none'><svg xmlns="http://www.w3.org/2000/svg"  className='h-6 w-6 mr-2' fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>Select an image</label>
              <input type="file" id='image' onChange={handleImageChange} className='hidden'/>
              {newImage ?  <span className='ml-4'>File: {newImage.name}</span> : ''}

          </div>

          <div className="mb-6">
              <label className='text-left'>
              <input type="checkbox" checked={forSale} onChange={e => { setForSale(e.target.checked);}} className='h-4 w-4 mr-2'/>
              I'm open to sell this project
              </label>
          </div>

        </form>

      </div>

    <div className='flex justify-center'>
        <div className='w-full  '>
          <p className='text-left sm:text-center'>
            <button type='button' onClick={handlePreviewClick} className='mb-4 underline  text-blue-600 hover:text-blue-800 focus:outline-none'>{preview? 'Close preview' : 'See how it looks (preview)'}</button>          
          </p>
          {preview ? 
            <>
            <p className='font-bold text-2xl text-center text-gray-400'>Preview</p>
            <ProjectCard preview={true} name={name} description={desc} images={tempImage ? tempImage: image} forSale={forSale} username={authToken.username} avatar={authToken.avatar} feedType='project' />
            </>
            : ''}
        </div>
    </div>

    <div className="flex">
        <button onClick={doSave} disabled={saveInProgress} className="flex justify-center whitespace-pre text-center font-bold border-2 border-black rounded-md shadow-emerald-big py-1 px-2 hover:bg-emerald focus:outline-none" type="submit">
          Publish {saveInProgress ? <Loader type="ThreeDots" color="#000000" height={20} width={30} /> : ''}
        </button>

        <button onClick={() => {props.setOpenOverlay(false)}} className="text-center font-bold border-2 border-black rounded-md shadow-gray-big py-1 px-2 ml-5 hover:bg-gray-400 focus:outline-none" type="submit">
          Nevermind
        </button>

    </div>

  </div>
  );
}


