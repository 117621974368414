import React, {useState, useEffect} from 'react';
import CommentsService from '../../services/CommentsService';
import CommentCard from '../Comments/CommentCard';
import Reply from '../Comments/Reply';
import Loader from "react-loader-spinner";

export default function CommentList(props) {
    const [comments, setComments] = useState([]);
    const [replyMessage, setReplyMessage] = useState("");
    const [mounted, setMounted] = useState(false);

    const getComments = async (id) => {        
        let commentsList;
        if (props.cardType === 'project')
            commentsList = await CommentsService.getProjectComments(id);
        else
            commentsList = await CommentsService.getPostComments(id);

        if (commentsList)
        {
            setComments(commentsList);
            //console.log(commentsList)
        }    
        if (!commentsList || commentsList.length == 0){
            setReplyMessage("Be the first one to comment");
        }
        else {
            setReplyMessage("Add comment");
        }
      
      };

      useEffect( () => {

        // here check if project or post and load comments...
        getComments(props.id, props.cardType);

        setMounted(true);
    }, []); 



if (!mounted)
    return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
else
    return (
            <div >                
                {(!comments || comments.length == 0) ? "There are no comments yet.":""}
                <Reply incrementCommentsCount={props.incrementCommentsCount} id={props.id} parentId={null} refreshComments={getComments} text={replyMessage} cardType={props.cardType}/>
                {comments.filter(x => x.parentId == null).map(function(comment, index){
                return (
                    <CommentCard key={index} incrementCommentsCount={props.incrementCommentsCount} refreshComments={getComments} isMaker={(comment.userId === props.userId)} userId={props.userId} id={props.id} commentId={comment.id} body={comment.body} createdAt={comment.createdAt} username={comment.username} avatar={comment.avatar} comments={comments} cardType={props.cardType}/>
                    )
                }
                )}

            </div>
    );

}