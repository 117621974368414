import React, { useState, useEffect, useRef } from 'react';
import NotificationsOverlay from './NotificationsOverlay';


export default function NotificationsBell(props) {
    const [count, setCount] = useState(0);
    const [openOverlay, setOpenOverlay] = useState(false);

    // listener to close the modal on Escape click
    const close = (e) => {
        if(e.key === 'Escape'){
            document.body.classList.remove('modal-open');
            setOpenOverlay(false);
            
            // cannot access the state from a bind event so do the hack above
            //OpenNotifWindow();
        }
    }

    useEffect(() => {                

        let eventSource = new EventSource(process.env.REACT_APP_MAKERS_API + "/notifications/new/" + props.userId);

        // trigger new message
        eventSource.onmessage = e => {
            const ret = e.data;
            setCount(ret);
        }

        // install listener for Escape
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close)

    }, []);
    
    const OpenNotifWindow = () => {
        
        if (!openOverlay){
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
        //document.body.classList.toggle('modal-open', !openOverlay);

        setOpenOverlay(!openOverlay);

    }

    useEffect( () => {

      }, []);
       
    
return (
    <>
            
        <button type="button" onClick={()=>{OpenNotifWindow()}} className='flex mt-4 mr-4 focus:outline-none'>
            <span className="relative inline-block">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>                
                {count > 0 ? <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{count}</span> : ''}
            </span>                

        </button>

       {openOverlay? <NotificationsOverlay setOpenOverlay={OpenNotifWindow} />: ''}    
         

    </>



)
    }