import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";




export default function Index(props) {

    return (
        <>
        <Helmet>
        <title>Make.rs - Makers Stack</title>
        <meta name="description" content="Show your tech stack to stand out" />
        <body className="home-bg" />
        </Helmet>           

        <div className='mx-auto mb-10'>        
            <p className="text-center text-black text-4xl sm:text-6xl font-bold">Show your tech stack</p>
            <p className="text-center text-black font-bold text-xl sm:text-2xl mt-8">Add the techs you use and make your profile stand out</p>
            <p className='text-center text-xl'><Link to='/@cezar/stack' className="underline text-blue-600 hover:text-blue-800">Example</Link></p>
        </div>
        <br />
        <div className='container mx-auto justify-center flex flex-wrap space-x-8'>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/oBmpHEQtEH19aGzc1tpsuh.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>.NET Core</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/hJAEzNr3tgMwHMrSXxkPTg.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>ASP.NET</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/amazon.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>AWS</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/angular.io.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Angular</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/ctdq1DBnPxrD3ERKbgvpty.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Azure</p>
            </div>            

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/bubble.io.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Bubble</p>
            </div>            

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/digitalocean.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Digital Ocean</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/tRUHcjLRGwwzGteCvwu6jc.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Docker</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/electronjs.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Electron</p>
            </div>              

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/figma.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Figma</p>
            </div>              

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/gatsbyjs.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Gatsby</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/github.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Github</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/golang.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Go</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/googlecloud.jpeg' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Google Cloud</p>
            </div> 

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/graphql.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>GraphQL</p>
            </div> 

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/heroku.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Heroku</p>
            </div> 

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/gohugo.io.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Hugo</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/invisionapp.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Invision</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/java.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Java</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/javascript.jpeg' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Javascript</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/kubernetes.io.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Kubernetes</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/laravel.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Laravel</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/linux.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Linux</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/macosx.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Mac OS</p>
            </div>

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/3CAJVcKXRZYJPuJuFm23oF.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Magento</p>
            </div>            

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/mongodb.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>MongoDB</p>
            </div>            

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/mysql.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>MySQL</p>
            </div>            

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/nginx.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>NGINX</p>
            </div>            

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/netlify.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Netlify</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/nodejs.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>NodeJS</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/notion.so.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Notion</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/nuxtjs.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>NuxtJS</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/php.jpeg' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>PHP</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/postgresql.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Postgres</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/prestashop.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Prestashop</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/python.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Python</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/reactjs.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>React</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/redis.io.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Redis</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/render.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Render</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/rubyonrails.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Ruby on Rails</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/shopify.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Shopify</p>
            </div>          

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/sqlserver.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>SQL Server</p>
            </div>    

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/sqlite.jpeg' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>SQLite</p>
            </div>    

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/stripe.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Stripe</p>
            </div>                                                                     

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/tailwindcss.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Tailwind CSS</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/typescript.jpeg' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>TypeScript</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/ubuntu.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Ubuntu</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/unity.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Unity</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/vercel.com.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Vercel</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/vim.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Vim</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/visualstudiocode.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>VS Code</p>
            </div>  

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/vuejs.org.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Vue</p>
            </div>             

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/hW1KrPdFnuqCUsQLEGJ7ih.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Webpack</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/jFfW3iK3VpUeoP19AQsPe7.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>WooCommerce</p>
            </div>   

            <div className='mx-2 my-2'>
                <img className='mx-auto' src='https://ik.imagekit.io/makers/techs/wordpress.png' style={{width: '60px'}}></img>
                <p className='text-center text-sm'>Wordpress</p>
            </div>   


        </div>

        <br /><br />
        </>
    );

}
