import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import ProjectCard from './ProjectCard';
import ProjectsService from '../../services/ProjectsService';
import {useAuth} from '../AuthContext';
import PostOverlay from '../PostOverlay';
import Loader from "react-loader-spinner";
import HeatMapCalendar from '../Social/HeatMapCalendar';


export default function ProjectsByUser(props)  {
  const location = useLocation();
  const { authToken } = useAuth();
  const [projects, setProjects] = useState (null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [newProject, setNewProject] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const doConfirmDelete = async (id) => {    
    setDeleteId(id);
    setConfirmDelete(true);
  };

  const doDelete = async () => {    
    
    if (deleteId > 0) {
      //console.log(deleteId)
      await ProjectsService.deleteProject(deleteId);
      // update state to remove the project
      const items = projects.filter(item => item.id !== deleteId);
      setProjects(items);  

      setDeleteId(0);
    }
    setConfirmDelete(false);

  };

  const handleConfirmCancel = async (id) => {    
    setConfirmDelete(false);
  };

  useEffect(  () => {

    const loadData = async() => {
      // get projects
      const ret = await ProjectsService.getProjectsByUserId(props.userId);
      if (ret) {
        setProjects(ret);
      }
    }
    loadData();

    return () => {
      setProjects([])
    }
    
  }, [location.search]);


  if (projects)
    return (
        <>
        <div className='container mx-auto flex justify-center'>
          <div className='w-full md:w-2/3 ml-0 pb-2 mr-2 sm:mx-auto'>
          {projects.length == 0 ? <p className='text-gray-400 text-xl mt-10 text-center'>{authToken && authToken.id == props.userId ? <>Ugh... you didn't share any project yet. 👉 <button onClick={()=> {setNewProject(true);}} className='underline text-blue-600 hover:text-blue-800 focus:outline-none'>Add your first one</button></> :"This maker didn't share any project yet."}</p>: ''}
          {projects.length > 0 ?
            
                projects.map(function(project, index){
                  return (
                    <ProjectCard key={project.id} onDelete={doConfirmDelete} index={index} id={project.id} claps={project.claps} name={project.name} description={project.description} images={project.images} url={project.url} forSale={project.forSale} userId={project.userId} fullName={project.fullName} username={project.username} avatar={project.avatar} bio={project.bio} commentsCount={project.comments} />
                    )
                  }
                )
            : ''}
        </div>
          <div className='hidden md:block md:w-1/4'>
            <HeatMapCalendar userId={props.userId}/>
          </div>           
        </div>

{newProject ? <PostOverlay setOpenOverlay={setNewProject} postType='project' mode='newProject'/> : ''}

{confirmDelete? <>
  <div className="fixed z-10 inset-0 overflow-y-auto" ariaLabelledby="modal-title" role="dialog" ariaModal="true">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ariaHidden="true"></div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" ariaHidden="true">&#8203;</span>

      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start ">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">

              <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" ariaHidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>

            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                Delete alert
              </h3>
              <div className="mt-2">
                <p className="text-lg text-gray-500">
                  Are you sure you want to delete this project? This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 text-center">
          <button type="button" onClick={doDelete} className="w-full sm:w-auto inline-flex justify-center font-bold border-2 border-black rounded-md shadow-red py-2 px-4 hover:bg-red-500 focus:outline-none">
            Yes, delete
          </button>
          <button type="button" onClick = {handleConfirmCancel} className="mt-3 sm:mt-0 sm:ml-3 w-full sm:w-auto inline-flex justify-center font-bold border-2 border-black rounded-md shadow-gray py-2 px-4 hover:bg-gray-400 focus:outline-none">
            Nevermind
          </button>
        </div>
      </div>

    </div>
  </div>    
</>
: ''}    
        </>
    );
  else  
  return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
}

