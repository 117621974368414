import React, { useState, useEffect } from 'react';
import NewPost from './Posts/NewPost';
import NewProject from './Projects/AddProject';

export default function PostOverlay(props)  {
    const [postType, setPostType] = useState(props.postType);

    useEffect( () => {

    }, []);

    return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="block justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

        <div className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity" aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-2/3">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start ">
                <div className="w-full mt-3  sm:mt-0 sm:ml-4 sm:mr-4 text-left">
                    {props.mode !== 'editProject' && props.mode !== 'editPost'? 
                        <>
                        <button onClick={() => {setPostType('update')}} className={'text-sm sm:text-lg font-bold text-gray-500 hover:text-gray-900 focus:outline-none ' +  (postType== 'update' ? ' bg-yellow text-gray-900 rounded-md px-2 py-1' :'' )}>✅ Post an update</button>
                        <button onClick={() => {setPostType('project')}} className={'ml-4 sm:ml-10 text-sm sm:text-lg font-bold text-gray-500 hover:text-gray-900 focus:outline-none ' + (postType== 'project' ? ' bg-yellow text-gray-900 rounded-md px-2 py-1' :'' )}>💎 Post a project</button>
                        </>
                        : ''
                    }
                    {props.mode === 'editProject' ? <span className={'text-lg font-bold hover:text-gray-900 focus:outline-none  bg-yellow text-gray-900 rounded-md px-2 py-2' }>💎 Edit project</span>:''}
                    {props.mode === 'editPost' ? <span className={'text-lg font-bold hover:text-gray-900 focus:outline-none  bg-yellow text-gray-900 rounded-md px-2 py-2' }>✅ Edit post</span>:''}

                    <div className="mt-8">

                        { postType === 'update' ? <NewPost id={props.id} setOpenOverlay={props.setOpenOverlay} userId={props.userId}/> : <NewProject id={props.id} setOpenOverlay={props.setOpenOverlay} mode={props.mode}/> }
                        
                    </div>
                </div>
            </div>
            </div>
        </div>

        </div>
    </div>    
    );
}

