import React, { useState, useEffect, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import AuthenticationService from '../services/AuthenticationService';
import {useAuth} from './AuthContext';
import ReactGA from 'react-ga4';
import Post from './Post';
//import Plausible from 'plausible-tracker'; // npm plausible-tracker
import NotificationsBell from './Notifications/NotificationsBell';

export default function Header() {

    const history = useNavigate();
    const [navOpen, setNavOpen] = useState (false);
    const { authToken, setAuthToken } = useAuth();
    //
    const container = useRef(null);
    //
    const location = useLocation();
    //
    /* const { trackPageview } = Plausible({
      domain: 'make.rs',
      trackLocalhost: false,
    }) */

    //console.log('Auth token');
    //console.log(authToken);

    const signOut = () => {
        AuthenticationService.signOut();
        setAuthToken();
        history.push('/');
        window.location.reload();
    }

    const flipNavOpen = () => setNavOpen(!navOpen);

    const handleNavClick = () => {
        flipNavOpen();
    };

    useEffect(() => {


      const handleOutsideClick = (event) => {
        if (!container.current.contains(event.target)) {
          if (!navOpen) return;
          setNavOpen(false);
        }
      };
  
      window.addEventListener('click', handleOutsideClick);
      return () => window.removeEventListener('click', handleOutsideClick);
    }, [navOpen, container]);
  
    useEffect(() => {
      const handleEscape = (event) => {
        if (!navOpen) return;
  
        if (event.key === 'Escape') {
            setNavOpen(false);
        }
      };
  
      document.addEventListener('keyup', handleEscape);
      return () => document.removeEventListener('keyup', handleEscape);
    }, [navOpen]);
    
    // location change so call GA
    useEffect( () => {
      // google analytics
      // ReactGA.initialize('UA-173942389-1'); 
      // ReactGA.pageview(window.location.pathname + window.location.search);
      // GA4
      ReactGA.initialize('G-RMYRNB5VXP');
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
 

      //
      // plausible
      //trackPageview();


    }, [location]);
    return (<>

<nav className="mb-20 " ref={container}>
  <div className="max-w-full px-4 mx-auto ">
    <div className="relative flex items-center justify-between h-16">
    
    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
        {/*Mobile menu button*/}
        <button type="button" onClick={handleNavClick} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
          <span className="sr-only">M</span>
          <svg className={(!navOpen? "block": "hidden") + " h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>

          <svg className={(navOpen? "block": "hidden") + " h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

      </div>
      <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div className="flex-shrink-0 flex items-center">
          <a href="/">
            <img className="block lg:hidden h-10 w-auto" src="/logo.svg" alt="Make.rs" />
            <img className="hidden lg:block h-12 w-auto" src="/logo.svg" alt="Make.rs" />
          </a>
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

      {authToken ? (<>
        <div className="ml-3 relative hidden sm:block">
          <div className='flex content-center align-middle'>
            <Post userId={authToken.id}/>
            <NotificationsBell userId={authToken.id}/>
            <button type="button" onClick={handleNavClick} className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
              <span className="sr-only">Open user menu</span>
              <img className="h-12 w-12 rounded-full" src={authToken.avatar && authToken.avatar != 'undefined'? 'https://ik.imagekit.io/makers/avatars/tr:w-100/' + authToken.avatar : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} alt="" />
            </button>
          </div>

          <div className={(navOpen? "block": "hidden") + " origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg py-1 bg-white border-2 border-black ring-1 ring-black ring-opacity-5 focus:outline-none"} role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <p className="font-bold text-black text-center pt-2">{authToken.name ?<b>{authToken.name}</b> : ''}</p>
            <p className=" text-black text-center pb-2">@{authToken.username}</p>
            <hr></hr>
            <Link to="/" className="block px-4 py-1 text-md  text-gray-700 hover:bg-gray-100" onClick={handleNavClick}>🌐 Community</Link>
            <Link to={"/@" + authToken.username } className="block px-4 py-1 text-md text-gray-700 hover:bg-gray-100" onClick={handleNavClick}>😎 My profile</Link>
            <Link to='/settings' className="block px-4 py-1 text-md text-gray-700 hover:bg-gray-100" onClick={handleNavClick}>⚙️ My settings</Link>
            <Link to='/about' className="block px-4 py-1 text-md text-gray-700 hover:bg-gray-100" onClick={handleNavClick}>ℹ️ About</Link>
            <Link to='#' onClick={signOut} className="block px-4 py-1 text-md text-gray-700 hover:bg-gray-100" >🚪 Sign out</Link>

          </div>
        </div>
        </>): (
        <div className="ml-3 relative hidden sm:block">
            <Link to="/" className="text-center font-bold border-2 border-black bg-white rounded-md shadow-yellow py-1 px-2 mr-4 hover:bg-yellow">Feed</Link>
            <Link to="/storyteller" className="text-center font-bold border-2 border-black bg-white rounded-md shadow-yellow py-1 px-2 mr-4 hover:bg-yellow">Storyteller</Link>
            <Link to="/stacks" className="text-center font-bold border-2 border-black bg-white rounded-md shadow-yellow py-1 px-2 mr-4 hover:bg-yellow">Stacks</Link>
            <Link to="/about" className="text-center font-bold border-2 border-black bg-white rounded-md shadow-yellow py-1 px-2 mr-4 hover:bg-yellow">About</Link>
            <Link to="/sign-in" className="text-center font-bold border-2 border-black bg-yellow rounded-md shadow-yellow py-1 px-2 hover:bg-yellow">Sign in</Link>
          
        </div>)}
      </div>
    </div>
  </div>

  <div className="sm:hidden" id="mobile-menu">
    <div className="px-2 pt-2 pb-3 space-y-1">
        {authToken ? (
        <>
            <Post userId={authToken.id}/>
            <Link to="/" className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>🌐 Community</Link>
            <Link to={"/@" + authToken.username } className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>😎 My profile</Link>
            <Link to='/settings' className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>⚙️ My settings</Link>
            <Link to='/about' className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>ℹ️ About</Link>
            <Link to='#' onClick={signOut} className={(navOpen? "block": "hidden") + " text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} >🚪 Sign out</Link>
        
        </>)
        : 
        (<>
            <Link to="/" className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>Feed</Link>
            <Link to="/story" className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>Storyteller</Link>
            <Link to="/stacks" className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>Stacks</Link>
            <Link to="/about" className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>About</Link>
            <Link to="/sign-in" className={(navOpen? "block": "hidden") +" text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"} onClick={handleNavClick}>Sign in</Link>
        
        </>)}

    </div>
  </div>
</nav>



</>
    )

}


