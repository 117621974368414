import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, useParams, Navigate } from 'react-router-dom';
import './App.css';
import UserProfile from './components/Users/Profile/Index';
import ProjectDetail from './components/Projects/ProjectDetail';
import PostDetail from './components/Posts/PostDetail';
import Settings from './components/Users/Settings';
import SignIn from './components/Users/SignIn';
import SignUp from './components/Users/SignUp';
import Header from './components/Header';
import {AuthContext} from './components/AuthContext';
import AuthenticationService from './services/AuthenticationService';
import NotFound from './components/NotFound';
import Home from './components/Home/Home';
import Terms from './components/Home/Terms';
import Welcome from './components/Users/Welcome';
import About from './components/Home/About';
import UserList from './components/Users/UserList';
import Stacks from './components/Stacks/Index';
//import Test from './components/Test/Index';
import Storyteller from './components/Home/Storyteller';


export default function App(props) {

  const user = AuthenticationService.getCurrentUser();

  const [authToken, setAuthToken] = useState(user); // pass these to auth context
  
  // hook functions sent to the context below
  const setToken = (data) => {
    AuthenticationService.saveCurrentUser(data);
    setAuthToken(data);
  }


  const UserNameWrapper = ()=>  {
    const {username} = useParams()
    if (username.startsWith('@', 0) === true)
    {
        return <UserProfile username={username.substring(1)} />
    }
    else 
    {
        return <NotFound />
    }
  }

  const PrivateRoute_Settings = ()=>  {
    //console.log( authToken)
    if (authToken != null) 
      return <Settings />
    else  {
      return <Navigate to="/sign-in" replace/>
    }
  }

  const PrivateRoute_Welcome = ()=>  {
    if (authToken) 
        return <Welcome />
    else 
        return <Navigate to="/sign-in" replace />
  }

  return (


    <div>
   
    <BrowserRouter>

    <AuthContext.Provider value={{authToken, setAuthToken: setToken}}>
      <Header/>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/leaderboard" element={<Home />} />
            <Route path="/forsale" element={<Home />} />
            <Route path="/following" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />   
            <Route path="/storyteller" element={<Storyteller />} />
            <Route path="/makingof" element={<Storyteller />} />
            <Route path="/story" element={<Storyteller />} />
            <Route path="/sign-in" element={<SignIn />}></Route>   
            <Route path="/sign-up" element={<SignUp />}></Route>   
            <Route path="/project/:slug" element={<ProjectDetail /> }></Route>
            <Route path="/post/:slug" element={<PostDetail />}></Route>
            <Route path="/settings" element={<PrivateRoute_Settings />}></Route>
            <Route path="/welcome" element={<PrivateRoute_Welcome />}></Route>
            <Route path='/stacks' element={<Stacks />} />
            {/* reserve routes for future expensations */}
            <Route path="/projects" element={<NotFound />}></Route>
            <Route path="/makerslist94130" element={<UserList />}></Route>
            <Route path="/marketplace" element={<NotFound />} />
            <Route path="/discussions" element={<NotFound />} />
            <Route path="/post" element={<NotFound />} />
            <Route path="/write" element={<NotFound />} />
            <Route path="/404" element={<NotFound />} />
            <Route path='/:username' element={<UserNameWrapper />} />
            <Route path='/:username/:dest' element={<UserNameWrapper />} />
            <Route path='*' exact={true} element={<UserNameWrapper />} />


        </Routes>
        </AuthContext.Provider>

    </BrowserRouter>
    </div>

  )
}

