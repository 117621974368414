export default function Avatar (props) {

    return (
        <div className="flex flex-wrap justify-center">
            <div className={props.size + "  " + props.extra}>
                <img src={props.src} className={"shadow rounded-full ' + props.size + ' align-middle border-none"} />
            </div>
        </div>

    )
}