import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {useAuth} from '../AuthContext';
import GenerateSlug from '../../utils/GenerateSlug';
import CardActions from '../Social/CardActions';
import CommentList from '../Comments/CommentList'; 

export default function ProjectCardCard(props) {
  const { authToken } = useAuth();
  const [showComments, setShowComments] = useState(false);
  const [commentsCount, setCommentsCount] = useState(props.commentsCount);


  const incrementCommentsCount = () => {
      setCommentsCount(commentsCount+1);
  }

  const showCommentsBlock = () => {
    setShowComments(!showComments);
  }
  

  return (
    <>
    <div className={'flex ' + (showComments ? '' : 'mb-10 ')}>
        {/* social side */}
        {!props.preview? 
        <CardActions onDelete={props.onDelete} showCommentsBlock={showCommentsBlock} commentsCount={commentsCount} claps={props.claps} projectId={props.id} projectName={props.name} cardType='project' username={props.username}/>
        : ''}

        {/* the project card */}
        <div className={'w-full pb-4 bg-white ' + (showComments ? 'rounded-tl-xl rounded-tr-xl border-l-2 border-white shadow-md' : 'border-l-2 border-white rounded-xl shadow-md') } >        
            <div className='w-full pb-0 flex '>
                <div className='float-left '>

                    <div className=" mx-4 my-4 flex">     
                        <div className='float-left flex-shrink-0'>       
                            <Link to={'/@' + props.username}>                                
                                <img className="rounded-full float-left h-12 w-12" src={props.avatar && props.avatar != 'undefined'? "https://ik.imagekit.io/makers/avatars/" + props.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} /> 
                            </Link>
                        </div>
                        <div className='float:left'>
                            <span className=" ml-2 h-full text-black text-lg font-bold"><Link to={'/@' + props.username} className='flex-item'>{props.fullName && props.fullName != 'undefined' ? props.fullName: '@' + props.username}</Link></span>
                            <span className='flex-item text-lg whitespace-pre-wrap'> made </span>
                            <span className='flex-item text-black font-bold text-lg bg-yellowLight rounded-md px-1 py-1'><Link to={"/project/" + GenerateSlug (props.id, props.name)}>{props.name}</Link></span>  
                            {props.forSale ? <span className={'text-center text-black font-bold   rounded-md bg-purple-300 py-1 px-1 ml-3 ' }>💰 For sale</span> : ''}
                            <span className='flex pl-2 text-gray-400 text-sm'>{props.bio && props.bio !='undefined' ? props.bio : ''}</span>
                        </div>
                    </div>

                    <div className='block w-full'>
                        <p className='text-gray-700 mx-4'>{props.description.length > 280 ? <span>{props.description.substring (0, 280)} <Link to={"/project/" + GenerateSlug (props.id, props.name)} className='text-gray-400 hover:text-gray-700'> ... more</Link></span> : props.description}</p>
                    </div>

                    {props.images ?
                <div className='flex space-x-2 mx-4 mt-2'> 
                    {!props.preview?
                    <>
                        <Link to={"/project/" + GenerateSlug (props.id, props.name)}>
                            <img src={"https://ik.imagekit.io/makers/projects/" + (props.images ? props.images + '?tr=w-320': 'no-img.jpeg')} loading='lazy' className='w-auto align-middle'/>
                        </Link>
                    </>
                    :
                    <>
                    <img src={props.images ? (props.images.startsWith('blob') ? props.images: 'https://ik.imagekit.io/makers/projects/' + props.images + '?tr=w-320') : 'https://ik.imagekit.io/makers/projects/no-img.jpeg?tr=w-320'} className={'align-middle ' + (props.images.startsWith('blob') ? "w-320": "" ) }/>
                    </>
                    }

                </div>
                : '' }
                </div>


                
            </div>


        </div>
    </div>
    {/* comments */}
    {showComments ? 
        <div className='flex'>
            <div className='w-12 mr-4 sm:mr-6'></div>
            <div className="comments w-full rounded-b-xl shadow-md pl-4 py-2 mb-10 bg-white">                
                <CommentList incrementCommentsCount={incrementCommentsCount} id={props.id} userId={props.userId} cardType='project'/>        
            </div>
        </div>
     : ''}
    

    </>
  );


}

