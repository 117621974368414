import React, { useState, useEffect } from 'react'
import {useParams, useLocation} from 'react-router-dom';
import ProjectsService from '../../services/ProjectsService';
import FeedsService from '../../services/FeedsService';
import FeedDate from './FeedDate';
import ProjectCard from '../Projects/ProjectCard';
import PostCard from '../Posts/PostCard';
import Loader from "react-loader-spinner";
import {useAuth} from '../AuthContext';
import InfiniteScroll from "react-infinite-scroll-component";

export default function HomeContent (props) {
    const { authToken } = useAuth();
    const [projects, setProjects] = useState([]);    
    const [hasMore, setHasMore] = useState(false); 
    const [days, setDays] = useState([]);
    const [mounted, setMounted] = useState(false);

    // timestamp to see if I need to refresh the useEffect following modal action
    const location = useLocation();
    //let params = new URLSearchParams(search);
    //let ts = params.get('ts');
    //console.log(location)

    function addDays(newValue) {
        let tempDays = days;
        tempDays.push (newValue);
        setDays(tempDays);

      }

    const fetchData = async(force) => {
        let ret;
        switch (props.category)
        {
            case 'everyone':                
                ret = await FeedsService.getFeeds( (projects && !force? projects.length : 0) );
                break;
            case 'following':
                ret = await FeedsService.getFeedsFollowing( (projects && !force? projects.length : 0)) ;
                break;
            case 'forsale':
                ret = await ProjectsService.getProjectsForsale( (projects && !force? projects.length : 0)) ;
                break;    
                    
        }
    
        //console.log(ret)
        if (ret){
            // need to concat the arrays for infinite scroll to work (we need to add the new items to the existing array)
            if (projects && !force) {
                const prevprojects = [...projects];
                const items = prevprojects.concat(ret);
                setProjects(items); 
                
            }
            else {                
                setProjects(ret); // first time run (force = true) or projects is null
                //console.log(ret)
            }

            // check if has More items to load for infinite scroll
            if (ret.length < 10) 
                setHasMore(false); 
            else 
                setHasMore(true);

        }
        else {
            setProjects(null);
            setHasMore(false);
        }

    }

    useEffect(   () => {
        const loadData = async () => {
            setMounted(false);
            setProjects(null); // to force clean the dom before loading hte new projects
        
            // force fetchData following change in props.category or the new timestamp
            setDays([]);
            await fetchData(true);

            setMounted(true);
        }
        loadData();

    }, [props.category, location.search]);



    if (!mounted) 
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else 
        return (
            <>
                {props.category == 'following' && projects.length == 0 ? <p className='text-center text-gray-400 text-xl mt-10'>Ugh... there's nothing to show for now. Follow some makers first.</p>: ''}
                {props.category == 'forsale' && projects.length == 0 ? <p className='text-center text-gray-400 text-xl mt-10'>Ugh... there's no project for sale right now.  Check again later.</p>: ''}

                
                <InfiniteScroll
                    className=''
                    dataLength={projects.length}
                    next={() => {fetchData(false)}}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                >
                    {
                    projects.map(function(project, index){
                    return (
                        <div key={index}>
                            {props.category == 'everyone' || props.category =='following'? <FeedDate createdAt={project.createdAt} days={days} addDays={addDays} /> : ''}

                            {project.feedType == 'project' ? 
                                <ProjectCard  key={project.id + 'proj'} category={props.category} id={project.id} claps={project.claps} hideActions={true} name={project.name} description={project.description} images={project.images} url={project.url} forSale={project.forSale} userId={project.userId} fullName={project.fullName} username={project.username} avatar={project.avatar} bio={project.bio} createdAt={project.createdAt} commentsCount={project.comments} />    
                                : 
                                <PostCard  key={project.id + 'post'} category={props.category} id={project.id} claps={project.claps} hideActions={true} images={project.images} userId={project.userId} fullName={project.fullName} username={project.username} avatar={project.avatar} bio={project.bio} createdAt={project.createdAt} message={project.message} projectId={project.projectId} projectName={project.projectName} commentsCount={project.comments} />
                        }
                        </div>
                        )
                    }
                    )}

                </InfiniteScroll>

            </>
        ) ;
}

