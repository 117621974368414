import React , {useState, useEffect, useCallback, useRef} from 'react';
import StacksService from '../../services/StacksService';
import {useAuth} from '../AuthContext';
import Loader from "react-loader-spinner";
import extractDomain from "extract-domain";


export default function AddTech(props) {
    const [name, setName] = useState(null);
    const [domain, setDomain] = useState(null);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const focusRef = useRef(null);

    const { authToken } = useAuth();

    const handleNameChange = useCallback(e => {        
        setName(e.target.value)                          
      }, []);

    const handleDomainChange = useCallback(e => {        
        setDomain(e.target.value)                          
    }, []);

    const  doSave = async (event) => {
        event.preventDefault();
        setSaveInProgress(true);
        //await Sleep(5000);

        const domainClean = extractDomain(domain);
        //console.log(domainClean);

        // submit the new tech
        try {
            const tech = await StacksService.newTech(name, domainClean);
            // push the new tech back to select
            props.handleAddSelect(tech);
            //console.log(tech);
            console.log('Tech Saved');
        }
        catch (error) {
            console.log("Save tech fail: error = { " + error.toString() + " }");
            //setError({error: "An error has ocurred"});
        }
        setSaveInProgress(false);

        // hide the form
        props.setAddNew(false); 

        // inform about a tech change
        props.setTechChanged(true);

        // focus the select
        props.showSelectRef();
      }  
    
    useEffect( async () => {
        focusRef.current.focus();
      }, []);
    

    return (
        <div className='mt-4 md:ml-4' >
            <div className='w-full md:w-4/12 text-center'>
                <p className='font-bold text-lg'>Add a new tech</p>
            </div>
            <div className='md:flex mb-4'>
                <div className='md:w-1/12'>
                    Name
                </div>
                <div className='md:w-5/12'>
                    <input type='text' ref={focusRef} onChange={handleNameChange} className='border-2 border-black rounded-md py-2 px-4 w-full focus:outline-none' placeholder='Example: React'></input>
                </div>
            </div>
            <div className='md:flex mb-4'>
                <div className='md:w-1/12'>
                    Website
                </div>
                <div className='md:w-5/12'>
                    <input type='text' onChange={handleDomainChange} className='border-2 border-black rounded-md py-2 px-4 w-full focus:outline-none' placeholder='Example: http://reactjs.com/'></input>
                    <p className='text-gray-500 text-sm'>We need the website to grab its image. We will review it later to make sure it's ok.</p>
                </div>
            </div>
            <div className='flex w-full md:w-4/12 justify-center'>
                <button onClick={doSave} disabled={saveInProgress} className="flex justify-center whitespace-pre	 text-center font-bold border-2 border-black rounded-md shadow-emerald-big py-2 px-4 hover:bg-emerald focus:outline-none" >
                    Submit  {saveInProgress ? <Loader type="ThreeDots" color="#000000" height={20} width={30} /> : ''}
                </button>
                <button onClick={()=>{props.setAddNew(false); props.showSelectRef();}} disabled={saveInProgress} className="text-center font-bold border-2 border-black rounded-md shadow-gray-big py-2 px-4 ml-5 hover:bg-gray-400 focus:outline-none" >
                    Cancel
                </button>

            </div>


        </div>
    );

}
