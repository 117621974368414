import axios from "axios";


class ClapsService {


  clapPost(accessToken, postId) {
    return axios.post(process.env.REACT_APP_MAKERS_API + "/claps/post/" + postId, [], { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

  unclapPost(accessToken, postId) {
    return axios.delete(process.env.REACT_APP_MAKERS_API + "/claps/post/" + postId, { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

  clapProject(accessToken, postId) {
    return axios.post(process.env.REACT_APP_MAKERS_API + "/claps/project/" + postId, [], { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

  unclapProject(accessToken, postId) {
    return axios.delete(process.env.REACT_APP_MAKERS_API + "/claps/project/" + postId, { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }


}

export default new ClapsService();
