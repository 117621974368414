import React, { useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom";
import UsersService from '../../services/UsersService';
import Loader from "react-loader-spinner";


export default function NewUsers()  {

    const [users, setUsers] = useState();
    const [mounted, setMounted] = useState (false);

    useEffect( () => {
      const loadData = async() => {
        const ret  = await UsersService.getNewestUsers();
        if (ret){
            if (ret.length > 0)
              setUsers(ret);
            else
              setUsers(null); 

        }
        setMounted(true);
      }
      loadData();
    }, []); 


  {/*  if not mounted show display the component title */}
  if (!mounted)   
    return (
      <div className='w-full'>
        <div className='hidden  md:block '>
        <p className="text-black text-2xl font-bold mb-6 ">✨ New makers</p>
        <div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>
       </div>
      </div>
    );

  else
    return (
        <div className='w-full'>
          {users ? (
            <div className='hidden  md:block '>
            <p className="text-black text-2xl font-bold mb-6 ">✨ New makers</p>

            {users.filter(x=>x.avatar !='undefined' && x.avatar !=null).slice(0, 20).map(function(user, index){
              return (
                  <div key={user.id} className='flex mb-1 items-center'>
                    <img src={user.avatar && user.avatar !== 'null' && user.avatar !== 'undefined'? 'https://ik.imagekit.io/makers/avatars/' + user.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} className='w-20 h-20 rounded-full flex-none'  />
                    
                    <div className='ml-1'>
                    <Link to={'/@' + user.username} className='underline text-blue-600 hover:text-blue-800'>@{user.username}</Link>
                    {user.bio && user.bio !='undefined' ? <p className='text-sm text-gray-500'>{user.bio}</p> : ''}
                    </div>
                  </div>
                )
              }
            )}

          {/* 
            <div className='mt-10'>
            <a href="https://www.producthunt.com/posts/make-rs?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-make-rs" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=294146&theme=light" alt="Make.rs - The place where makers show the world what they've made | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
            </div>
          */}
          </div>
          ) : (null)}
          </div>
        ) 
}

