import MenuLink from './MenuLink';



export default function MenuLinks(props){

    return (
        <div className='flex'>
            <MenuLink to={'/@' + props.username } isSelected={props.category == 'feed' ? 'true': ''} title='✅ Posts' />
            <MenuLink to={'/@' + props.username + '/projects'} isSelected={props.category == 'projects' ? 'true': ''} title='💎 Projects' />
            <MenuLink to={'/@' + props.username + '/stack'} isSelected={props.category == 'stack' ? 'true': ''} title='⚒️ Stack' />
            <MenuLink to={'/@' + props.username + '/following'} isSelected={props.category == 'following' ? 'true': ''} title='Following' />
            <MenuLink to={'/@' + props.username + '/followers'} isSelected={props.category == 'followers' ? 'true': ''} title='Followers' />
        </div>
    );
}