import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../AuthContext';
import UsersService from '../../services/UsersService';
import {Helmet} from "react-helmet";
import Loader from "react-loader-spinner";

export default function Welcome() {

  const { authToken, setAuthToken } = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState ();
  const [bio, setBio] = useState ();
  const [bioCharsLeft, setBioCharsLeft] = useState (160);
  const [location, setLocation] = useState ();
  const [avatar] = useState ();
  const [newAvatar, setNewAvatar] = useState ();
  const [tempImage, setTempImage] = useState (null);
  const [error, setError] = useState();
  const [saveInProgress, setSaveInProgress] = useState(false);


  const handleAvatarChange = useCallback(e => {        
    setNewAvatar(e.target.files[0]);
    setTempImage(URL.createObjectURL(e.target.files[0]));
  }, []);

  const handleBioChange = useCallback(e => {        
    setBio(e.target.value)        
    const charsCount = 160 - e.target.value.length
    if (charsCount < 0) 
      charsCount = 0;
    setBioCharsLeft(charsCount);
                 
  }, []);

  const doSettingsUpdate = async (e) => {
    e.preventDefault();
    setSaveInProgress(true);

    UsersService
        .updateSettings(authToken.accessToken.token, authToken.id, null, name,  bio, location, avatar, newAvatar)
        .then(
        async () => {
          // update context
          // get the 
          let avatarSrc = avatar;
          const updatedUser = await UsersService.getUserById(authToken.id);
          
          if (newAvatar) {
            //console.log('found new avatar')
            //console.log(updatedUser)
            avatarSrc = updatedUser.avatar;
          }

          // overwrite authToken user info ..
          let newAuthToken = authToken;
          newAuthToken.name = name;
          newAuthToken.avatar = avatarSrc;
          setAuthToken(newAuthToken);
         
          // redirect to profile
          navigate('/@' + updatedUser.username);
        },
        error => {
          console.log("Update error = { " + error.toString() + " }");
          setError("Error updating profile");
        }
    );
    setSaveInProgress(false);

  };
  
  useEffect( () => {
    // mark that the user saw the page to avoid redirecting here in the future
    authToken.firstSignIn = false;
    setAuthToken(authToken);

  }, []);

  if (authToken) {
    
    // render the component
    return (
      <>
      <Helmet>
        <title>Make.rs | Welcome</title>
      </Helmet>        

    <div className='container mx-auto '>
      <p className='font-bold text-4xl text-center'>Welcome onboard, @{authToken.username}! 🎉</p>
      <br /><br />
      <div className='container mx-auto grid sm:grid-cols-2 sm:gap-20'>
        <div >
          <br />
          <p className='text-2xl'> 👉 Let's finalize your profile for a great experience. </p>
          <br /><br /><br />
          <form method='post' onSubmit={doSettingsUpdate}>
            <div className="mb-6">
                <label htmlFor='image' className='inline-flex cursor-pointer text-center bg-white border-2 border-black rounded-md shadow-yellow p-2 hover:bg-yellow focus:outline-none'><svg xmlns="http://www.w3.org/2000/svg"  className='h-6 w-6 mr-2' fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>Select your profile image</label>
                <input type="file" id='image' onChange={handleAvatarChange} className='hidden'/>
            </div>

            <div className="mb-6">
                <input id="name" placeholder='Your name' onChange={e => { setName(e.target.value);}} value={name ? name: ""} className='border-2 border-black rounded-md p-2 w-full focus:outline-none'/>
            </div>

            <div className="mb-6">
                <textarea id="bio" placeholder='Your bio (make it short and sweet)' onChange={handleBioChange} value={bio ? bio: ""} maxLength={160} className='border-2 border-black rounded-md p-2 w-full focus:outline-none'/>
                <p className='text-gray-500 text-right text-xs'>Characters Left: {bioCharsLeft}</p>
            </div>

            <div className="mb-6 w-full">
                <input id="location" placeholder='Your location' onChange={e => { setLocation(e.target.value);}} value={location ? location: ""} className='border-2 border-black rounded-md p-2 w-full focus:outline-none'/>
            </div>

            <div className="flex justify-center">
                <button disabled={saveInProgress} className="flex justify-center whitespace-pre text-center font-bold border-2 border-black rounded-md shadow-emerald-big p-2 hover:bg-emerald" type="submit">
                  Save & onward {saveInProgress ? <Loader type="ThreeDots" color="#000000" height={20} width={30} /> : ''}
                </button>
            </div>
          </form>
      </div>
      <div className='hidden sm:block'>
          <br />
          <p className='text-2xl text-center'> 👁️ This is how others will see you. </p>
          <br /><br /><br />
          <div className='container flex bg-white shadow-xl rounded-xl min-h-44'>
              <div className=' flex-none'>
                <img className='shadow rounded-full flex-none h-36 w-36 my-4 ml-4 align-middle border-none' src={tempImage ? tempImage: 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=200'}  />
              </div>
                <div className='ml-10 mt-4'>
                    <div className='flex'>
                        <p className='font-bold text-black text-2xl'>{name ? name : 'Your name'} </p>  
                    </div>
                    <p className='text-gray-500 text-lg'>@{authToken.username}</p>
                    <p className='text-lg mt-4'>{bio ? bio: 'Your bio'}</p>
                    <p className='text-sm mt-4 flex text-gray-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
</svg> {location ?  location: 'Your location'}</p>
                </div>
            </div>

      </div>
      </div>
    </div>
    </>
    );
  }
  else {
    return (
      <div>
        <h2>Not logged in</h2>
      </div>
    );
  }
  
  
}