import axios from "axios";
import AuthenticationService from '../services/AuthenticationService';


class PostsService {

  // create new post
  newPost = async(message, projectId, images) => {

    const user = AuthenticationService.getCurrentUser();

    let fd = new FormData();

    fd.append('message', message);
    fd.append('projectId', projectId);
    fd.append('images', JSON.stringify(images));

    const res = await axios.post(process.env.REACT_APP_MAKERS_API + "/posts/", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}});
    return res.data;
    /*
    return axios.post(process.env.REACT_APP_MAKERS_API + "/posts/", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          return res.data;

      });*/
  }

  // create new post
  updatePost = async(id, message, projectId, images) => {

    const user = AuthenticationService.getCurrentUser();

    let fd = new FormData();

    fd.append('message', message);
    fd.append('projectId', projectId);
    fd.append('images', JSON.stringify(images));

    const res = await axios.patch(process.env.REACT_APP_MAKERS_API + "/posts/" + id, fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}});
    return res.data;
  }

  uploadFiles = async(images) => {

    const user = AuthenticationService.getCurrentUser();

    let fd = new FormData();
    if (images) {
      [...images].forEach(image => {
        //console.log(image.name);
        fd.append('images', image, image.name);
      });
      
    }

    const res = await axios.post(process.env.REACT_APP_MAKERS_API + "/posts/upload", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}});
    return res.data;

  }

  getPost = async (id) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/posts/" + id)
      .then ( (res) => {
          return res.data;
      });
  }

  getPosts = async () => {
    const user = AuthenticationService.getCurrentUser();

    return axios.get(process.env.REACT_APP_MAKERS_API + '/posts', { headers: {"Authorization" : `Bearer ${user.accessToken.token}`} })
    .then(res => {
      const posts  = res.data.map(obj => ({id: obj.id, avatar: obj.avatar, username: obj.username}));
      return posts;
    });
  }

  getPostsByUserId = async (userId) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/posts/byuser/' + userId)
    .then(res => {
      const posts = res.data.map(obj => ({id: obj.id, images: obj.images, fullName: obj.fullName, createdAt: obj.createdAt, username: obj.username, avatar: obj.avatar, bio: obj.bio, claps:obj.claps, comments: obj.comments, message:obj.message, projectId:obj.projectId, projectName:obj.projectName}));

      return posts;
    });
  }

  getPostsByProjectId = async (projectId) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/posts/byproject/' + projectId)
    .then(res => {
      const posts = res.data.map(obj => ({id: obj.id, images: obj.images, fullName: obj.fullName, createdAt: obj.createdAt, username: obj.username, avatar: obj.avatar, bio: obj.bio, claps:obj.claps, comments: obj.comments, message:obj.message, projectId:obj.projectId, projectName:obj.projectName}));

      return posts;
    });
  }

  // delete post
  deletePost = async(id) => {
    const user = AuthenticationService.getCurrentUser();

    const ret = await axios.delete(process.env.REACT_APP_MAKERS_API + "/posts/" + id, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`} });
    return ret.data;
    /*
    return axios.delete(process.env.REACT_APP_MAKERS_API + "/posts/" + id, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`} })
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
      */
  }  
}

export default new PostsService();
