import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import UsersService from '../../services/UsersService';
import AuthenticationService from '../../services/AuthenticationService';
import {useAuth} from '../AuthContext';
import Sleep from '../../utils/Sleep';
import validator from 'validator';
import {Helmet} from "react-helmet";
import Loader from "react-loader-spinner";


export default function Settings()  {

  const { authToken, setAuthToken } = useAuth();

  const navigate = useNavigate();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [user, setUser] = useState(null);
  const [name, setName] = useState (null);
  const [email, setEmail] = useState ();
  const [bio, setBio] = useState (null);
  const [bioCharsLeft, setBioCharsLeft] = useState (160);
  const [location, setLocation] = useState (null);
  const [avatar, setAvatar] = useState ();
  const [tempImage, setTempImage] = useState (null);
  const [newAvatar, setNewAvatar] = useState ();
  const [enableContact, setEnableContact] = useState (true);
  const [enableNotifications, setEnableNotifications] = useState (true);
  const [errors, setErrors] = useState({
    email : false,
  });
  

  const handleAvatarChange = useCallback(e => {        
    setNewAvatar(e.target.files[0]);
    setTempImage(URL.createObjectURL(e.target.files[0]));
  }, []);

  const handleBioChange = useCallback(e => {        
    setBio(e.target.value)        
    const charsCount = 160 - e.target.value.length
    if (charsCount < 0) 
      charsCount = 0;
    setBioCharsLeft(charsCount);
                 
  }, []);

  
  const doCancel = async (e) => {        
    e.preventDefault();
    navigate('/@' + authToken.username);

  };

  const doSettingsUpdate = async (e) => {
    e.preventDefault();
    setSaveInProgress(true);

    let hasErrors = false;

    // do validation 
    if (!validator.isEmail(email)) {
      hasErrors = true;
      setErrors({
        ...errors,
        email : true 
      });
    }

    // end validation

    if (!hasErrors) {
    UsersService
        .updateSettings(authToken.accessToken.token, authToken.id, email, name,  bio, location, avatar, newAvatar, enableContact ? 1:0, enableNotifications ? 1:0)
        .then(
        async () => {
          // update context
          // get the 
          let avatarSrc = avatar;
          //const updatedUser = await UsersService.getUserById(authToken.id);
          const updatedUser = await UsersService.getUserMe(authToken.accessToken.token);

          if (newAvatar) {
            //console.log('found new avatar')
            //console.log(updatedUser)
            avatarSrc = updatedUser.avatar;
          }

          // overwrite authToken user info ..
          let newAuthToken = authToken;
          newAuthToken.name = name;
          newAuthToken.avatar = avatarSrc;
          setAuthToken(newAuthToken);
         
          // redirect to profile
          navigate('/@' + authToken.username);
        },
        error => {
          console.log("Update error = { " + error.toString() + " }");
          //setError("Error updating profile");
        }
    );
      }

      setSaveInProgress(false);

  };
  
  useEffect(  () => {

    const loadData = async() => {
      const ret = await UsersService.getUserMe(authToken.accessToken.token);
      if (ret) {
        setUser(ret);
        setName (ret.name && ret.name != 'null' ? ret.name : '');
        setEmail (ret.email);
        setBio (ret.bio && ret.bio != 'null' && ret.bio != 'undefined' ? ret.bio: '');
        setBioCharsLeft (ret.bio && ret.bio != 'null' && ret.bio != 'undefined' ? 160 - ret.bio.length : 160);
        setLocation (ret.location && ret.location != 'null' && ret.location != 'undefined' ? ret.location : '');
        setAvatar(ret.avatar != 'null' && ret.avatar != 'undefined'? ret.avatar: null);
        setEnableContact(ret.enableContact == true ? true: false);
        setEnableNotifications(ret.enableNotifications == true ? true: false)
      }
    }
    loadData();

  }, []);
    

  if (!user) 
  return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
  else 
    return ( 
      <>
      <Helmet>
        <title>Make.rs | Settings</title>
      </Helmet>    

      <div className='container mx-auto '>
      <p className='font-bold text-4xl text-center'>Settings</p>
      <br /><br /><br />
      <div className='container mx-auto grid sm:grid-cols-2 sm:gap-20'>
        <div>          
          <form method='post' onSubmit={doSettingsUpdate}>
    
            <div className="mb-6">
              <label className="block text-gray-400 font-bold text-2xl text-center mb-1 md:mb-5 pr-4"> Profile </label>
            </div>

            <div className=" mb-6">
              <label htmlFor='image' className='inline-flex cursor-pointer bg-white text-center border-2 border-black rounded-md shadow-yellow p-2 hover:bg-yellow focus:outline-none'><svg xmlns="http://www.w3.org/2000/svg"  className='h-6 w-6 mr-2' fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>Select your profile image</label>
            <input type="file" id='image' onChange={handleAvatarChange} className='hidden'/>
              
            </div>

            <div className="mb-6">
                <input id="email" onChange={e => { setEmail(e.target.value);}} placeholder='Your email address' value={email ? email: ""} className={'border-2 rounded-md p-2 w-full focus:outline-none ' + (errors.email ? 'border-red-500': 'border-black')}/>
            </div>

            <div className="mb-6">
                <input id="name" onChange={e => { setName(e.target.value);}} placeholder='Your name' value={name ? name: ""} className='border-2 border-black rounded-md p-2 w-full focus:outline-none'/>
            </div>
            <div className="mb-6">
                <textarea id="bio" onChange={handleBioChange} placeholder='Your bio (make it short and sweet)' value={bio ? bio: ""} maxLength={160} className='border-2 border-black rounded-md p-2 w-full focus:outline-none'/>
                <p className='text-gray-500 text-right text-xs'>Characters Left: {bioCharsLeft}</p>
            </div>
            <div className="mb-6">
                <input id="location" onChange={e => { setLocation(e.target.value);}} placeholder='Your location' value={location ? location: ""} className='border-2 border-black rounded-md p-2 w-full focus:outline-none'/>
            </div>

            <div className="mb-6">
              <label className="block text-gray-400 text-2xl font-bold text-center mb-1 md:mb-5 pr-4"> Notifications </label>
            </div>

            <div className="mb-6">
                <label>
                <input type="checkbox" checked={enableContact} onChange={e => { setEnableContact(e.target.checked);}} className='h-4 w-4 mr-2'/>
                Display a contact me button on my profile page (coming soon)
                </label>
                
                <br />

                <label>
                <input type="checkbox" checked={enableNotifications} onChange={e => { setEnableNotifications(e.target.checked);}} className='h-4 w-4 mr-2'/>
                Email me when there are new comments or replies for me
                </label>

            </div>
            <div className="flex justify-center">
                <button disabled={saveInProgress} className="flex justify-center whitespace-pre text-center font-bold border-2 border-black rounded-md shadow-emerald-big py-2 px-4 hover:bg-emerald focus:outline-none" type="submit">
                  Save settings {saveInProgress ? <Loader type="ThreeDots" color="#000000" height={20} width={30} /> : ''}
                </button>

                <button onClick={doCancel}className="text-center font-bold border-2 border-black rounded-md shadow-gray-big py-2 px-4 ml-5 hover:bg-gray-400 focus:outline-none" type="submit">
                  Cancel
                </button>

            </div>
          </form>
          </div>        
          <div>
            <p className='font-bold text-2xl text-center text-gray-400 mb-6'>Preview</p>
            <div className='container flex  bg-white shadow-xl rounded-xl min-h-44'>
                <div className='flex-none w-36 my-4 ml-4'>
                  <img className='rounded-full h-36 w-36 flex-none' src={tempImage ? tempImage: (avatar ? 'https://ik.imagekit.io/makers/avatars/' + avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=200')}  />
                </div>
                  <div className='ml-10 mt-4'>
                      <div className='flex'>
                          <p className='font-bold text-black text-2xl'>{name ? name : 'Your name'} </p>  
                      </div>
                      <p className='text-gray-500 text-lg'>@{authToken.username}</p>
                      <p className='text-lg mt-4'>{bio && bio != 'null' && location != 'undefined' ? bio: 'Your bio'}</p>
                      <p className='text-sm mt-4 flex text-gray-500'>
                        <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg> {location && location != 'null' && location != 'undefined' ?  location: 'Your location'}</p>
                  </div>
              </div>

          </div>
        </div>
        <br /><br />
    </div>
    </>

    
    )
  

}
