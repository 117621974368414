import React, { useState, useEffect } from 'react';
import PostOverlay from './PostOverlay';

export default function Post(props)  {
    const [openOverlay, setOpenOverlay] = useState(false);

    // listener to close the modal on Escape click
    const close = (e) => {
        if(e.key === 'Escape'){
            document.body.classList.remove('modal-open');
            setOpenOverlay(false);
        }
    }

    useEffect( () => {

        // install listener for Escape
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close)

    }, []);

    const OpenOverlay = () => {
        //console.log('overlay set ' + openOverlay)
        
        if (!openOverlay){
            document.body.classList.add('modal-open');
        }
        else {
            //console.log('remove')
            document.body.classList.remove('modal-open');
        }
        //document.body.classList.toggle('modal-open', !openOverlay);

        setOpenOverlay(!openOverlay);

    }    
    return (
    <>
    <button onClick={() => {OpenOverlay()}} className="flex text-center font-bold border-2 border-black bg-white rounded-md shadow-emerald px-1 mt-2 h-9 py-1 mr-5 hover:bg-emerald focus:outline-none"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg> <span className='ml-1'>POST</span></button>


    {openOverlay? <PostOverlay setOpenOverlay={OpenOverlay} userId={props.userId} postType='update'/>: ''}    

    </>
    );
}

