import React, { useState, useEffect } from 'react';
import NotificationUser from './NotificationUser';
import GenerateSlug from '../../utils/GenerateSlug';
var dayjs = require ('dayjs');
var utc = require('dayjs/plugin/utc')
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(relativeTime)

export default function NotificationCard(props)  {
    const [message, setMessage] = useState(null);
    const [displayDate, setDisplayDate] = useState();


    useEffect(  () => {

        let message;

        if (props.notification.action === 'follow') message = ' follows you';
        if (props.notification.action === 'clap') {
            if (props.notification.objectType === 'post') {
                message = ' clapped your ';
    
            }
            else {
                message = ' clapped your project ';
            }
        }
        if (props.notification.action === 'comment') {
            if (props.notification.objectType === 'post') {
                message = ' commented your ';
            }
            else {
                message = ' commented your project ';
            }
        }
        if (props.notification.action === 'reply') {
            if (props.notification.objectType === 'post') {
                message = ' replied to your comment on ';
            }
            else {
                message = ' replied to your comment on ';
            }
        }

        // prepare display date
        var notifDate = dayjs(props.notification.createdAt);
        var isToday = false;
        var format = 'D MMM YYYY';
        if (notifDate.isSame(dayjs().utc(), 'day')) {
            isToday = true;
        }
        // set post date format depending if it is the same year or not
        if (notifDate.isSame(dayjs().utc(), 'year')) {
            format = 'D MMM';
        }
        

        if (isToday) {
            setDisplayDate(notifDate.fromNow())
        }
        else {
            setDisplayDate(notifDate.format(format))
        }        


        setMessage(message);
    }, []);

    return (
        <div className={'mb-4 text-md ' + (props.notification.seen === 0 ? 'font-bold' : '')}>   
            {/*made by user*/}

            <NotificationUser avatar={props.notification.sourceUserAvatar} username={props.notification.sourceUserUsername} name={props.notification.sourceUserName} />

            <span className='ml-1'>{message} {props.notification.objectType == 'post' ?  <a href={'/post/' + props.notification.objectId} className='underline text-blue-600 hover:text-blue-800'>post</a>: (props.notification.objectType=='project' ? <a href={'/project/' + GenerateSlug (props.notification.objectId, props.notification.objectName)} className='underline text-blue-600 hover:text-blue-800'>{props.notification.objectName}</a>: '')}</span>
            <span className='text-gray-300 ml-2'>{displayDate}</span>

       </div>
    );
}

