import {Helmet} from "react-helmet";

export default function Terms () {


    return (
		<>
		<Helmet>
    	    <title>Make.rs | Terms of use</title>
			<body className="no-bg" />
	    </Helmet>        

		<div className='md:px-20'>
			<p className='text-center text-black text-4xl font-bold'>Terms of use</p>
			<br /><br />
			<div className='text-xl'>These terms apply to MAKE.RS website.
					<ul>
						<li>MAKE.RS is crowdsourced and has user-generated content, so we don't guarantee the data accuracy.</li>
						<li>We do not accept any liability for any loss or damage which is incurred from you using this website or reading any of its content. </li>
						<li>You allow us to publish the content you contribute to this site.</li>
						<li>When providing feedback (through the feedback form or any other way), you agree to let us use it to improve the site.</li>
						<li>We collect analytics data including your IP, location and actions on this site (via Google Analytics) to make it better.</li>
						<li>If you excessively promote your website/app/business/yourself or spam any of our users, we have the right to ban you for life.</li>
						<li>We have zero tollerance for racism, trolling, insulting, personal attacks, harassement. </li>
					</ul>
					If you want to delete your account &amp; and data permanently please send your request to hey@make.rs 
			</div>
		</div>
		</>
    );   
}