import React from 'react';


export default function NotificationUser(props)  {
    return(
            <a href={'/@' + props.username} className='flex items-center float-left'>
            <img className="rounded-full h-6 " src={props.avatar && props.avatar != 'undefined' ? "https://ik.imagekit.io/makers/avatars/" + props.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} /> <span className="inline-block ml-1 h-full underline text-blue-600 hover:text-blue-800">{props.name && props.name != 'undefined' ?  props.name : '@' + props.username}</span>
            </a>

    );
}

