import React, { useState, useEffect } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import SignUpMagic from '../Users/SignupMagic';
import {useAuth} from '../AuthContext';
import HomeContent from './HomeContent';
import {Helmet} from "react-helmet";
import 'animate.css';
import NewUsers from '../Users/NewUsers'
import NewProjects from '../Projects/NewProjects';
import Leaderboard from '../Users/Leaderboard';

export default function Home () {
    const { authToken } = useAuth();
    const [filter, setFilter] = useState('everyone');
    const location = useLocation();

    useEffect( () => {
        if (location.pathname == '/') {
            setFilter('everyone');
        }

        if (location.pathname == '/leaderboard') {
            setFilter('leaderboard');
        }
        if (location.pathname == '/forsale') {
            setFilter('forsale');
        }
        if (location.pathname == '/following') {
            setFilter('following');
        }

    }, [location.pathname]);

    
    if (authToken && authToken.firstSignIn === true) {
        return <Navigate to={"/welcome"} />;
    }


    return (
    <>
        {filter !== 'forsale' ? 
        <Helmet>
            <title>What are you working on? | Make.rs</title>
            <meta name="description" content="The place where makers show the world what they've made" />
            <body className="home-bg" />
        </Helmet>
        : 
        <Helmet>
            <title>Projects for sale | Make.rs</title>
            <meta name="description" content="Projects for sale listed directly by the makers" />
            <body className="home-bg" />
        </Helmet>

        }
      

    <div className='mx-auto'>        
        <p className="text-center text-black text-4xl sm:text-6xl font-bold">What are you working on?</p>
    </div>

    {!authToken ? 
    <div className='mx-auto'>        
        <p className="text-center text-black font-bold text-xl sm:text-2xl mt-8">Create your <span className=' rounded-full border-none bg-yellowLight px-1'>Maker</span> page and show the world what you've done</p>
        <SignUpMagic />
    </div> : <></>
    }


    {authToken ? 
    <></>
    : 
    <>
    <br /><br /><br />
    <p className="text-center text-black text-xl sm:text-2xl font-bold ">You'll be in good company with other makers like you</p>
    <div className='flex justify-center mt-10 mb-28'>
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/1102341.jpeg' className='shadow rounded-full h-20 w-20 align-middle border-none' />
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/1222271.jpeg' className="shadow rounded-full h-20 w-20 align-middle border-none transform translate-y-10 "/>
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/1438275.jpeg' className='shadow rounded-full h-20 w-20 align-middle border-none' />
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/415829.jpeg' className="shadow rounded-full h-20 w-20 align-middle border-none transform translate-y-10 "/>
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/2379004.jpeg' className='shadow rounded-full h-20 w-20 align-middle border-none' />
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/247885.jpeg' className="shadow rounded-full h-20 w-20 align-middle border-none transform translate-y-10 "/>
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/1681010.jpeg' className='shadow rounded-full h-20 w-20 align-middle border-none' />
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/672445.jpeg' className="hidden sm:block shadow rounded-full h-20 w-20 align-middle border-none transform translate-y-10 "/>
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/736716.jpeg' className='hidden sm:block shadow rounded-full h-20 w-20 align-middle border-none' />
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/774909.jpeg' className="hidden sm:block shadow rounded-full h-20 w-20 align-middle border-none transform translate-y-10 "/>
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/1304647.jpeg' className='hidden sm:block shadow rounded-full h-20 w-20 align-middle border-none' />
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/428328.jpeg' className="hidden sm:block shadow rounded-full h-20 w-20 align-middle border-none transform translate-y-10 "/>
        <img src='https://s3.eu-west-3.amazonaws.com/make.rs/faces/598745.jpeg' className='hidden sm:block shadow rounded-full h-20 w-20 align-middle border-none' />

    </div>
    </>
}

        {/* latest entries */}
        <div className='container mx-auto mt-10'>
            {!authToken ? <p className="text-center text-black text-xl sm:text-2xl font-bold">Latest entries from our makers</p> : ''}
            

            <br />

            <div className='container mx-auto flex justify-center mb-10'>
                <Link to='/' className={"bg-bg font-bold text-center text-sm border-2 border-black rounded-md shadow-yellow py-2 px-2  hover:bg-yellow focus:outline-none " + (filter === 'everyone'? "bg-yellow" : "")}>Everyone</Link>
                <Link to='/following' className={"bg-bg font-bold text-center text-sm border-2 border-black rounded-md shadow-blue-300 py-2 px-2 ml-5 hover:bg-blue-300 focus:outline-none " + (filter === 'following'? "bg-blue-300" : "")}>Following</Link>
                <Link to='/forsale' className={"bg-bg font-bold text-center text-sm border-2 border-black rounded-md shadow-purple-300 py-2 px-2 ml-5 hover:bg-purple-300 focus:outline-none " + (filter === 'forsale'? "bg-purple-300" : "")}>💰 For sale</Link>
                <Link to='/leaderboard' className={"bg-bg font-bold text-center text-sm border-2 border-black rounded-md shadow-emerald-300 py-2 px-2 ml-5 hover:bg-emerald-300 focus:outline-none " + (filter === 'leaderboard'? "bg-emerald-300" : "")}>🔥 Leaderboard</Link>

            </div>

            <br />

            <div className='flex'>
            <div className='w-full  md:w-2/3 mr-2 sm:mx-auto'>

            {filter !== 'leaderboard' ?  <HomeContent category={filter} /> : <Leaderboard />}
            
            </div>
            <div className='md:w-1/4'>
                <NewUsers />
                <NewProjects />
               
            </div>
            </div>
        </div>


    <br /><br /><br /><br /><br /><br />
    </>
    );   
}