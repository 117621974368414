import ClapAction from './ClapAction';
import CommentAction from './CommentAction';
import ShareAction from './ShareAction';
import MoreAction from './MoreAction';

export default function CardActions(props) {

    
return (
    <div className='w-12 ml-2 mr-2 sm:mr-4 flex flex-col items-end justify-center'>
        <ClapAction clapsCount={props.clapsCount} claps={props.claps} postId={props.postId} projectId={props.projectId} cardType={props.cardType}/>
        <CommentAction showCommentsBlock={props.showCommentsBlock} commentsCount={props.commentsCount} postId={props.postId} projectId={props.projectId} cardType={props.cardType}/>
        {/*<ShareAction cardType={props.cardType} postId={props.postId} projectId={props.projectId} projectName={props.projectName} />*/}
        <MoreAction cardType={props.cardType} postId={props.postId} projectId={props.projectId} projectName={props.projectName} username={props.username}/>

    </div>

);


}