import React, { useState, useEffect  } from 'react';
import { Navigate, useLocation, Link } from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService';
import {useAuth} from '../AuthContext';
import Loader from "react-loader-spinner";
import validator from 'validator';
import {Helmet} from "react-helmet";

export default function SignIn(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [magicSent, setMagicSent] = useState(false);
  const { authToken, setAuthToken } = useAuth();

  // check if I have  a token param
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token')
  //console.log('token: ' + token);

  useEffect(  () => {
    const loadData = async() => {
        // login the user with the token
        if (token) {
            await AuthenticationService
                .signIn(token)
                .then(
                (data) => {
                    console.log('Set auth token')
                    setAuthToken(data);
                    setLoggedIn(true);
                },
                error => {
                    console.log("Login fail: error = { " + error.toString() + " }");
                    setIsError(true);
                }
            );
        }
    }
    loadData();

  }, []);
  
  if (loggedIn) {
    return <Navigate to={"/@" + authToken.username} />;
  }

  const doSignIn = async (event) => {
    event.preventDefault();

    if (validator.isEmail) {
    
        await AuthenticationService
            .getMagicLink(email)
            .then( () => {
                console.log('magic sent');
                setMagicSent(true);
                    }        
            ).catch (
            (error) => {
            console.log("Login fail: error = { " + error.toString() + " }");
            setIsError(true);
            });
        }
        else {
            setIsError(true);
        }
  }

    if (token) 
        return (
            <>
            <p className="text-center text-black text-4xl font-bold">Signing in ...</p>
            
            {isError ? 
                <div className="container mx-auto flex justify-center mt-10 whitespace-pre text-2xl">Oops, your magic link has expired. Please request a new one <Link to="/sign-in" className="underline text-blue-600 hover:text-blue-800">here</Link>.</div>
                : 
                <div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            }
            </>
        );
    else 
        return ( 
        <>
        <Helmet>
    	    <title>Make.rs | Sign in</title>
        </Helmet>   

        <div className="container mx-auto">

      
            <p className="text-center text-black text-4xl font-bold">Signing in is easy 👌</p>

            {
                magicSent ? 
                    <div className="mt-10 mx-auto text-2xl"><p className='text-center'>We emailed you the magic link. Click on that link and we will automatically log you in. </p></div> 
                    :             
                    <>
                    <p className="text-center text-black text-2xl mt-8">Enter your email below and we will send you a magic link to sign you in.</p>            
                    <div className="md:container md:mx-auto md:flex justify-center mt-20">
                        <form method="post" onSubmit={doSignIn}>            
                            <span className="text-2xl font-bold">Your email </span>
                            <input id="email" name="email" onChange={e => { setEmail(e.target.value);}} label="Email" className={"font-bold border-2  rounded-md py-2 px-4 ml-4 focus:outline-none " + (isError ? 'border-red-500':'border-black') }/>
                            <button type="submit" className=" text-center font-bold border-2 border-black rounded-md shadow-emerald py-2 px-4 ml-4 hover:bg-emerald focus:outline-none" >Sign in</button>
                            
                        </form>
                        
                    </div>
                    {isError ? <p className='text-red-500 font-bold text-center'>This email address is invalid</p>: ''}
                    
                    <div className=" text-center mt-20 text-2xl">Don't have an account?  <Link to='/sign-up' className='underline text-blue-600 hover:text-blue-800'>Sign up</Link></div>
                    </>    
            }
        </div>
        </>
        );
  
}

