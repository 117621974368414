import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import FeedsService from '../../services/FeedsService';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
var dayjs = require ('dayjs');
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function Leaderboard()  {

    const [users, setUsers] = useState([]);
    const [mounted, setMounted] = useState (false);

    useEffect( () => {
      const loadData = async () => {
        const ret  = await FeedsService.getLeaderboard();
        if (ret){
            if (ret.length > 0)
              setUsers(ret);
            else
              setUsers(null); 

        }
        setMounted(true);
      }
      loadData();
    }, []); 


  {/*  if not mounted show display the component title */}
  if (!mounted)   
    return (
    <>
       <Helmet>
        <title>Makers leaderboard | Make.rs</title>
        <meta name="description" content="The leaderboard with the most prolific makers this month" />
        <body className="home-bg" />
      </Helmet>  

      <div className='w-full'>
        <div className='block '>
        <p className="text-black text-2xl font-bold mb-6 ">🔥 Activity leaderboard</p>
        <div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>
       </div>
      </div>
    </>
    );

  else
    return (
        <>
       <Helmet>
        <title>Makers leaderboard | Make.rs</title>
        <meta name="description" content="The leaderboard with the most prolific makers this month" />
        <body className="home-bg" />
      </Helmet>  

        <div className='w-full'>
          {users ? (
            <div className='block '>
            <p className="text-black text-2xl font-bold mb-6 ">🔥 Activity leaderboard</p>

            <div className='bg-white rounded-xl shadow-md px-2 py-2'>
            {users.filter(x=>x.avatar !='undefined' && x.avatar !=null).slice(0, 20).map(function(user, index){
              return (
                  <div key={user.id} className='flex mb-4 items-center'>
                    <img src={user.avatar && user.avatar !== 'null' && user.avatar !== 'undefined'? 'https://ik.imagekit.io/makers/avatars/' + user.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} className='w-14 h-14 rounded-full flex-none'  />
                    
                    <div className='ml-1'>
                    <Link to={'/@' + user.username} className='underline text-blue-600 hover:text-blue-800'>@{user.username}</Link> <span className='text-sm font-bold bg-emerald-300 px-1 py-1 rounded-md'>🔥 {user.total}</span>
                    {user.bio && user.bio !='undefined' ? <p className='text-sm text-gray-500'>{user.bio}</p> : ''}
                    </div>
                  </div>
                )
              }
            )}
            </div>
            <p className='text-gray-500 text-sm mt-3'>The leaderboard is calculated based on the activity over a 30 days rolling period ({dayjs().utc().format('MMM D')} - {dayjs().utc().subtract(30, 'days').format('MMM D')})</p>
          </div>
          ) : (null)}
          </div>
        </>
        ) 
}

