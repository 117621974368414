import axios from "axios";


class UsersService {

  getUser(username) {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/byusername/" + username)
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });

  }

  getUserById(userId) {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/" + userId)
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });

  }

  getUserMe(accessToken) {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/me" , { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });

  }


  getNewestUsers () {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/new/")
    .then ( (res) => {
        return res.data;
    }).catch(err => {
      console.log(err);
      throw err;
    });
  }

  getUsers () {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/")
    .then ( (res) => {
        //console.log(res.data);
        return res.data;
    }).catch(err => {
      console.log(err);
      throw err;
    });
  }

  updateSettings(accessToken, userId, email, name, bio, location, oldAvatar, newAvatar, enableContact =1, enableNotifications =1) {

    let fd = new FormData();
    if (newAvatar) {
      fd.append('avatar', newAvatar, newAvatar.name);
    }
    if (email) { // do not include email in the update if is null (ex: when coming from welcome)      
      fd.append('email', email);
    }
    fd.append('name', name);
    fd.append('bio', bio);
    fd.append('location', location);
    fd.append('oldAvatar', oldAvatar);
    fd.append('enableContact', enableContact);
    fd.append('enableNotifications', enableNotifications);

    /*
    console.log('Form data...');
    for (var pair of fd.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }    
    console.log(fd); */

    return axios.patch(process.env.REACT_APP_MAKERS_API + "/users/" + userId , fd, { headers: {"Authorization" : `Bearer ${accessToken}`, "Content-type": "multipart/form-data"} })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });

    /*
    return axios.patch(API_URL + "/users/" + userId , {email, name, bio, location, file: avatar}, { headers: {"Authorization" : `Bearer ${accessToken}`, "content-type": "multipart/form-data"} })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
*/
  }


  hasFollow(accessToken ,followUserId) {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/hasfollow/" + followUserId, { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

  followUserId(accessToken, followUserId) {
    return axios.post(process.env.REACT_APP_MAKERS_API + "/users/follow/" + followUserId, [], { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

  unfollowUserId(accessToken, followUserId) {
    return axios.delete(process.env.REACT_APP_MAKERS_API + "/users/unfollow/" + followUserId, { headers: {"Authorization" : `Bearer ${accessToken}`} })
      .then ( (res) => {
          //console.log(res.data);
          return res.data;
      }).catch(err => {
        console.log(err);
        throw err;
      });
  }

  getFollowers (userId) {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/followers/" + userId)
    .then ( (res) => {
        //console.log(res.data);
        return res.data;
    }).catch(err => {
      console.log(err);
      throw err;
    });
  }

  getFollowing (userId) {
    return axios.get(process.env.REACT_APP_MAKERS_API + "/users/following/" + userId)
    .then ( (res) => {
        //console.log(res.data);
        return res.data;
    }).catch(err => {
      console.log(err);
      throw err;
    });
  }




}

export default new UsersService();
