import axios from "axios";
import AuthenticationService from '../services/AuthenticationService';


class StacksService {

  getTechs = async () => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/stacks/techs/')
    .then(res => {
      //console.log(res.data)
      return res.data;
    });
  }


  getStack = async (userId) => {
    //console.log('get stack')
    return axios.get(process.env.REACT_APP_MAKERS_API + '/stacks/' + userId)
    .then(res => {
      return res.data;
    });
  }

  // create new tech
  newTech = async(name, domain) => {
    const user = AuthenticationService.getCurrentUser();
    let fd = new FormData();
    fd.append('name', name);
    fd.append('domain', domain);

    return axios.post(process.env.REACT_APP_MAKERS_API + "/stacks/techs/", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }

  /*

  getGroups = async (userId) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/stacks/groups/' + userId)
    .then(res => {
      return res.data;
    });
  }

  // create new group
  newGroup = async(name, domain) => {
    const user = AuthenticationService.getCurrentUser();
    let fd = new FormData();
    fd.append('name', name);

    return axios.post(process.env.REACT_APP_MAKERS_API + "/stacks/groups/", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }

  // update group
  updateGroup = async(id, name) => {
    const user = AuthenticationService.getCurrentUser();
    let fd = new FormData();
    fd.append('name', name);

    return axios.patch(process.env.REACT_APP_MAKERS_API + "/stacks/groups/" + id, fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }

  // delete group
  deleteGroup = async(id) => {
    const user = AuthenticationService.getCurrentUser();

    return axios.delete(process.env.REACT_APP_MAKERS_API + "/stacks/groups/" + id, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }
  */
  
  // save a stack
  saveStack = async(stack) => {
    const user = AuthenticationService.getCurrentUser();

    //const values = stacks.map(obj => [obj.id, groupId, createdAt]);
    const values = [];
    for (const obj of stack) {
      values.push({techId: obj.id});
    }

    //console.log('stack values')
    //console.log(values)

    let fd = new FormData();
    fd.append('stack', JSON.stringify(values));


    return axios.post(process.env.REACT_APP_MAKERS_API + "/stacks/", fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"}})
      .then ( (res) => {
          //console.log(res);
          return res.data;

      });
  }
}

export default new StacksService();
