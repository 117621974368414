import React from 'react'
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";


export default function NotFound() {

    return (
        <>
        <Helmet>
    	    <title>Page not found</title>
            <body className="no-bg" />
        </Helmet>          

        <div className='text-center'>
            <p className='text-2xl font-bold'>Oops... the page you were looking for does not exist</p>
            <br /><br />
            <Link to='/' className='underline text-blue-600 hover:text-blue-800'>Go back home</Link>
        </div>
        </>
    )
}