import axios from "axios";
import AuthenticationService from '../services/AuthenticationService';


class FeedsService {

  getFeeds = async (offset) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/feeds/everyone/' + offset)
    .then(res => {
      const feeds = res.data.map(obj => ({id: obj.id, name: obj.name, description: obj.description, images: obj.images, url: obj.url, userId:obj.userId, fullName: obj.fullName, forSale: obj.forSale, createdAt: obj.createdAt, username: obj.username, avatar: obj.avatar, bio: obj.bio,  message:obj.message, projectId:obj.projectId, projectName: obj.projectName, claps:obj.claps, comments:obj.comments, feedType: obj.feedType}));
      return feeds;
    });
  }

  getFeedsFollowing = async (offset) => {
    const user = AuthenticationService.getCurrentUser();
    if (!user) return [];
    return axios.get(process.env.REACT_APP_MAKERS_API + '/feeds/following/' + offset, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`} })
    .then(res => {
      const feeds = res.data.map(obj => ({id: obj.id, name: obj.name, description: obj.description, images: obj.images, url: obj.url, userId:obj.userId, fullName: obj.fullName, forSale: obj.forSale, createdAt: obj.createdAt, username: obj.username, avatar: obj.avatar, bio: obj.bio,  message:obj.message, projectId:obj.projectId, projectName: obj.projectName, claps:obj.claps, comments:obj.comments, feedType: obj.feedType}));
      return feeds;
    });
  }

  getContributions = async (userId) => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/feeds/contributions/' + userId)
    .then(res => {
      return res.data;
    });
  }

  getLeaderboard = async () => {
    return axios.get(process.env.REACT_APP_MAKERS_API + '/feeds/leaderboard/')
    .then(res => {
      return res.data;
    });
  }

}

export default new FeedsService();
