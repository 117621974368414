import React , {useEffect, useState} from 'react';
var dayjs = require ('dayjs');
var utc = require('dayjs/plugin/utc')
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(localizedFormat)

export default function FeedDate(props) {

  const [day, setDay] = useState(null);

  
  useEffect(() => {

    const currentDate = dayjs(props.createdAt).utc();

    if (!props.days.includes(currentDate.format('L')))  {
        props.addDays(currentDate.format('L'));
        var today = dayjs().utc();
        
        if (currentDate.isSame(today, 'day')){
            setDay('Today');
        }
        else if (currentDate.isSame(today.subtract(1, 'days').startOf('day'), 'day')){
            setDay('Yesterday');
        }
        else {
            setDay(dayjs.utc(props.createdAt).format('MMMM D'));
        }
    }

}, []);

    if (!day) 
        return '';
    else 
    return (
        <div className='text-2xl mb-10 ml-16'><span className='font-bold'>👏 {day}</span></div> 
    );


}

