import React , {useState, useEffect} from 'react';
import StackCard from './StackCard';
import SelectTech from './SelectTech';


export default function StackList(props) {
    if (props.editMode) 

        return (
              
                <div className='w-full'>
                    <SelectTech defaults={props.stack} setEditMode={props.setEditMode}/>
                </div>

        );
    else 
        if (props.stack.length > 0 )
            return (
                <div className='flex flex-wrap space-x-4 pb-4'>     
                {
                    props.stack.map(function(tech, index) {
                        return (
                            <StackCard key={tech.id} name={tech.name} domain={tech.domain} logo={tech.logo} />
                        )
                    })

                }
                </div>

            );
        else
            return ('');
}
