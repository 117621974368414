import {Helmet} from "react-helmet";

export default function About () {

    //document.body.style.backgroundImage = 'none';

    return (
        
    <>
    <Helmet>
        <title>Make.rs | About</title>
    </Helmet>        

    <div className='px-2 md:px-20'>
        <p className='text-center text-black text-4xl font-bold'>About Make.rs</p>
        <br/><br/>
        <div className='text-xl'>
        <p>Make.rs' mission is to help you be successful by providing you the place to share your progress, learn, grow, and make new friends, all the way throughout your journey as maker.</p>
        <br/>
        <p className='font-bold'>For some, it's about the destination; for others, it's about the journey, and for some, it's both. No matter which type you are, Make.rs is for you. ❤️</p>
        <br />
        <p className='font-bold'>Motivation</p>
        <p >This is a place where makers show the world what they've made.</p>
        <p >It's a different kind of profile page where it's not about the companies you worked for but what you've done.</p>
        <p >It's also a place where you discover other makers like you.  Get feedback or let others know what you think about their work.</p>
        <p >It's where you find inspiration and motivation.</p>
        <p >It's where you learn and grow.</p>

        <br />
        <p className='font-bold'>Why free</p>
        <p >Make.rs is a project fueled by passion.</p>
        <p >Every time you use Make.rs, it gives purpose to my work. When you reach out with suggestions, feedback or just to say hi, it brightens my day.</p>
        <p> If you'd like to support me, consider buying me a coffee! Your support would be greatly appreciated! :)
                <a href="https://www.buymeacoffee.com/make.rs"><img src="/images/bmc-button.png" className="w-200"></img></a>
        </p>
        <br/>
        <p className='font-bold'>Want to help?</p>
        <p >Share Make.rs with your friends. I’d be grateful.</p>
        <p >Write a feedback. The key to make a great platform is listen to the people who use it.</p>
        <br/>

        <p className='font-bold'>Contact</p>
        <p >Feel free to send any question or feedback.</p>
        <p><a href="mailto:cezar@make.rs" className='underline text-blue-600 hover:text-blue-800'>cezar@make.rs</a></p>
        <p><a href="https://twitter.com/cezarfloroiu" target='_blank' className='underline text-blue-600 hover:text-blue-800'>Twitter</a></p>
        
        <br />
        {/*
        <p className='font-bold'>Github</p>
        <p >There is a github repo where you can submit bugs or make suggestions.</p>
        <p><a href="https://github.com/cezarfloroiu/Make.rs/issues" className='underline text-blue-600 hover:text-blue-800 visited:text-blue-600'>Github</a></p>
         */}
        <br />
        </div>
    </div>
    </>
    );   
}