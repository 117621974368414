import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from '../../AuthContext';
import UsersService from '../../../services/UsersService';
import { useNavigate } from 'react-router-dom';

export default function FollowUnfollow (props) {

    const [mounted, setMounted] = useState(false);
    const [follow, setFollow] = useState(false);
    const { authToken } = useAuth();
    const history = useNavigate();

    const handleUnfollowClick = async () => {
        await UsersService.unfollowUserId(authToken.accessToken.token, props.userId);
        setFollow(false);
    };

    const handleFollowClick = async () => {
        if (!authToken) {
            // user is authenticated so redirect to feed instead of default homepage
            history.push('/sign-in');
        }
        else {
            await UsersService.followUserId(authToken.accessToken.token, props.userId);
            setFollow(true);
    
        }
    
    };

    useEffect( () => {
        const loadData = async () => {
            // check if current user allready follows this user (props.username)
            if (authToken) {
                // check
                try {
                const ret  = await UsersService.hasFollow(authToken.accessToken.token, props.userId);
                if (ret.count != 0){
                    setFollow(true);
                }
            } catch (err) {}
            } // else do nothing

            setMounted(true);
        }
        loadData();

    }, []); 

    if (!mounted || (authToken && authToken.id === props.userId)) return (<div className='flex-1'></div>);
    else

    return (
        <div className='flex-1 text-left'>
        {!authToken ? <Link to='/sign-in' className='hidden sm:block w-20 text-xs text-center font-bold border-2 border-black rounded-md shadow-blue-300 pt-2 pb-1 px-1 ml-5 hover:bg-blue-300 focus:outline-none'>FOLLOW</Link> : 
        (follow? <button  onClick={handleUnfollowClick} className='text-xs text-center font-bold border-2 border-black rounded-md shadow-red-300 pt-2 pb-1 px-1 ml-5 hover:bg-red-300 focus:outline-none'>UNFOLLOW</button> : <button onClick={handleFollowClick} className='text-xs text-center font-bold border-2 border-black rounded-md shadow-blue-300 pt-2 pb-1 px-1 ml-5 hover:bg-blue-300 focus:outline-none'>FOLLOW</button>)}
        </div>
    );

}