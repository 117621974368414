import React , {useState, useEffect} from 'react';
import StacksService from '../../services/StacksService';
import {useAuth} from '../AuthContext';
import Loader from "react-loader-spinner";
import StackList from './StackList';

export default function StacksByUser(props) {
    const { authToken } = useAuth();
    const [stack, setStack] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect( () => {
        const loadData = async () => {
            setMounted(false);

            // get stacks
            const ret = await StacksService.getStack(props.userId);
            if (ret) {
                setStack(ret);
            }
            
            setMounted(true);
        }
        loadData();

        return () => {
            setStack([])
          }
    
        
      }, [editMode]);
    

    if (!mounted) 
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else
    return (

        <div className='container mx-auto flex justify-center'>

            {stack.length == 0 ? <p className='text-gray-400 text-xl mt-10'>{authToken && authToken.id == props.userId ? <>You didn't list your stack yet. 👉 <button  onClick={() => {setEditMode(!editMode)}} className='mb-2 underline text-blue-600 hover:text-blue-800 focus:outline-none'>List now</button></>: "This maker didn't list the tech stack yet."}</p>: ''}

            {stack.length > 0 || editMode ? 
            <div className='bg-white rounded-xl shadow-md pt-4 w-full md:w-2/3'>   
                <StackList stack={stack} editMode={editMode} setEditMode={setEditMode}/>
            
                { (authToken && authToken.id == props.userId)? 
                <div className='flex-none text-center'>
                    <button  onClick={() => {setEditMode(!editMode)}} className='underline text-blue-600 hover:text-blue-800 focus:outline-none'>{!editMode ? 'Edit stack' : 'Exit edit mode'}</button>
                </div>
                : ''
                }

            </div> : ''
            }
            <br /><br />
        </div>

    );

}



