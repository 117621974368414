import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import PostCard from '../Posts/PostCard';
import PostsService from '../../services/PostsService';
import {useAuth} from '../AuthContext';
import PostOverlay from '../PostOverlay';
import Loader from "react-loader-spinner";
import HeatMapCalendar from '../Social/HeatMapCalendar';


export default function PostsByUser(props)  {
  const { authToken } = useAuth();
  const [posts, setPosts] = useState (null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [newPost, setNewPost] = useState(false);
  const location = useLocation();
  
  
  const doConfirmDelete = async (id) => {    
    setDeleteId(id);
    setConfirmDelete(true);
  };

  const doDelete = async () => {    
    
    if (deleteId > 0) {
      await PostsService.deletePost(deleteId);
      // update state to remove the project
      const items = [...posts];
      const newArray = items.filter(item => item.id !== deleteId);
      setPosts(newArray);  

      setDeleteId(0);
    }
    setConfirmDelete(false);

  };

  const handleConfirmCancel = async () => {    
    setConfirmDelete(false);
  };

  useEffect(  () => {
    const loadData = async () => {
      // get user posts
      const ret = await PostsService.getPostsByUserId(props.userId);
      if (ret) {
        setPosts(ret);
      }

    }
    loadData();

    return () => {
      setPosts([])
    }

  }, [props.userId, location.search]);

  
  if (posts)
    return (
        <>
        <div className='container mx-auto flex justify-center'>
          <div className='w-full md:w-2/3 ml-0 pb-2 mr-2 sm:mx-auto '>
            {posts.length == 0 ? <p className='text-gray-400 text-xl mt-10 text-center'>{authToken && authToken.id == props.userId ? <>Ugh... you didn't share any post yet. 👉 <button onClick={()=> {setNewPost(true);}} className='underline text-blue-600 hover:text-blue-800 focus:outline-none'>Post your first one</button></> :"This maker didn't share any post yet."}</p>: ''}

            {posts.length > 0 ? 
              
                  posts.map(function(post, index){
                    return (
                      <PostCard key={post.id} onDelete={doConfirmDelete} id={post.id} claps={post.claps} images={post.images} fullName={post.fullName} username={post.username} avatar={post.avatar} bio={post.bio} message={post.message} projectId={post.projectId} projectName={post.projectName} commentsCount={post.comments} createdAt={post.createdAt}/>
                      )
                    }
                  )

            :''} 
         </div>

          <div className='md:w-1/4'>
            <HeatMapCalendar userId={props.userId}/>
          </div>           
        </div>

{newPost ? <PostOverlay setOpenOverlay={setNewPost} postType='update' mode='newPost'/> : ''}

{confirmDelete? <>
  <div className="fixed z-10 inset-0 overflow-y-auto" ariaLabelledby="modal-title" role="dialog" ariaModal="true">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ariaHidden="true"></div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" ariaHidden="true">&#8203;</span>

      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start ">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">

              <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" ariaHidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>

            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                Delete alert
              </h3>
              <div className="mt-2">
                <p className="text-lg text-gray-500">
                  Are you sure you want to delete this post? This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 text-center">
          <button type="button" onClick={() => {doDelete();}} className="w-full sm:w-auto inline-flex justify-center font-bold border-2 border-black rounded-md shadow-red py-2 px-4 hover:bg-red-500 focus:outline-none">
            Yes, delete
          </button>
          <button type="button" onClick = {() => {handleConfirmCancel()}} className="mt-3 sm:mt-0 sm:ml-3 w-full sm:w-auto inline-flex justify-center font-bold border-2 border-black rounded-md shadow-gray py-2 px-4 hover:bg-gray-400 focus:outline-none">
            Nevermind
          </button>
        </div>
      </div>

    </div>
  </div>    
</>
: ''}    
        </>
    );
  else  
  return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
}

