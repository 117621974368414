import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import PostsService from '../../services/PostsService';
import ProjectsService from '../../services/ProjectsService';
import validator from 'validator';
import Loader from "react-loader-spinner";
import {useAuth} from '../AuthContext';


export default function NewPost(props) {
    const { authToken } = useAuth();
    const [message, setMessage] = useState(null);
    const [images, setImages] = useState ([]);
    const [tmpImagesLoading, setTmpImagesLoading] = useState(false);
    const [projects, setProjects] = useState(null);
    const [projectId, setProjectId] = useState(undefined);
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate()
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [charsLeft, setCharsLeft] = useState (280);
    const [mounted, setMounted ] = useState(false);

    const handleImageChange = async (e) => {        
      //setImage(e.target.files[0]);

      setTmpImagesLoading(true);
      const files = await PostsService.uploadFiles(e.target.files);


      if (images.length == 0){
        setImages(files); 
      }
      else{

        // append new images        
        let newImages = [...images];
        for (let i=0; i< files.length; i++){
          newImages.push(files[i]);
        }

        setImages(newImages);
      }

      setTmpImagesLoading(false);

    };    

    const removeTmpImage = (index)=> {

      // remove from main array
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);

      
    };

    const handlePostChange = useCallback(e => {        
      setMessage(e.target.value)        
      const charsCount = 280 - e.target.value.length
      if (charsCount < 0) 
        charsCount = 0;
      setCharsLeft(charsCount);
                   
    }, []);

    const handleProjectChange = useCallback(e => {        
      if (e.target.value > 0 ) setProjectId(e.target.value);
    }, []);   



    useEffect( () => {

      const loadData = async() => {
        const projectList  = await ProjectsService.getProjectsByUserId(props.userId);
        if (projectList) setProjects(projectList);

        if (props.id) {
          // edit mode, so load & prefill the info
          const post = await PostsService.getPost(props.id);
          if (post) {
            setMessage(post.message);
            setCharsLeft(280-post.message.length);

            //setImages (post.images);
            console.log(post.images)
            if (post.images) {
              const tmpImages = post.images.split(',');
              setImages(tmpImages);
            }
            setProjectId (post.projectId);
          }
      
        }
        setMounted(true);
      }
      loadData();

    }, []); 

    const doPost = async(event) => {
      setSaveInProgress(true);

      let errors = false;

        event.preventDefault();

        if (message===null || validator.isEmpty(message.trim())) {
          errors = true;
          setHasError(true);
        }

        if (!errors) {

          if (props.id) {
            // update post
            try {
              await PostsService.updatePost(props.id, message, projectId, images);
              setHasError(false);
              setMessage('');
              props.setOpenOverlay(false); // close the overlay
              //history.push('/?ts=' + Date.now()); // timestamp to force refresh the feed
              navigate('/@' + authToken.username + '?ts=' + Date.now()); // timestamp to force refresh the feed
            } catch (error) {
              console.log("Error update post= { " + error.toString() + " }");
            }

          }
          else {
            // new post
            try {
              await PostsService.newPost(message, projectId, images);
              setHasError(false);
              setMessage('');
              props.setOpenOverlay(false); // close the overlay
              //history.push('/?ts=' + Date.now()); // timestamp to force refresh the feed
              navigate('/@' + authToken.username + '?ts=' + Date.now()); // timestamp to force refresh the feed
            } catch (error) {
              console.log("Error save post = { " + error.toString() + " }");
            }
          }

        }

      setSaveInProgress(false);

    }

    if (!mounted) return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else
    return (
        <div className='w-full'>
          <div className="mb-6 border-2 rounded-md border-black p-2">
            <textarea type='text' onChange={handlePostChange} value={message ? message: ""} maxLength={280} placeholder='What have you done?' rows={3} className={'  w-full focus:outline-none ' + (hasError ? 'bg-red-200': '')} />

            {/* view thumbnails */}
            {tmpImagesLoading? <Loader type="ThreeDots" color="#000000"  /> : ''}
            {images ?
            <div className='flex space-x-1'>
                {images.map(function(img, index){
                  return (
                    <div className='relative w-40'>
                      <img src={'https://ik.imagekit.io/makers/' + (props.id ? 'posts' : 'temp') + '/' + img + '?tr=w-160,c-at_max'} className='align-middle w-40'/>      
                      <button onClick={()=>{removeTmpImage(index)}} className='absolute top-2 right-2 bg-white opacity-75 text-gray-600 hover:text-red-800 focus:outline-none'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>                        
                      </button>

                    </div>
                    )
                  }
                )}              
              
            </div>
            : ''}

            {/* select image & character left*/}
            <div className='w-full flex'>
              <div className='align-bottom flex-1'>
                <label htmlFor='image' className='float-left align-bottom inline-flex cursor-pointer text-center hover:bg-yellow focus:outline-none'><svg xmlns="http://www.w3.org/2000/svg"  className='h-6 w-6' fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg></label>
                <input type="file" id='image' accept="image/*" multiple onChange={handleImageChange} className='hidden'/>
              </div>
              <div className='text-gray-500 flex-1 text-xs relative'><span className='absolute right-0 bottom-0'>Characters left: {charsLeft}</span></div>
            </div>

            
          </div>

          <div className="mb-6">
          </div>


          {projects ? 
          <div className='mb-6 flex'>
            <select value={projectId} className='left-0 border-2 border-black rounded-md p-2 cursor-pointer focus:outline-none hover:bg-yellow' onChange={handleProjectChange}>
                    <option key='0' value='0' >Tag one of your projects (optional)</option>
                    {
                    projects.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1).map(function(project, index){
                    return (
                      <option key={index} value={project.id} >{project.name}</option>
                        )
                    }
                    )}

            </select>
          </div>
          :''}


            <div className="flex">
              <button onClick={doPost} disabled={saveInProgress} className="flex justify-center whitespace-pre text-center font-bold border-2 border-black rounded-md shadow-emerald-big py-1 px-2 hover:bg-emerald focus:outline-none" >
                Publish {saveInProgress ? <Loader type="ThreeDots" color="#000000" height={20} width={30} /> : ''}
              </button>

              <button onClick={() => {props.setOpenOverlay(false)}} className="text-center font-bold border-2 border-black rounded-md shadow-gray-big py-1 px-2 ml-5 hover:bg-gray-400 focus:outline-none" type="submit">
                Nevermind
              </button>
            </div>

        </div>
    );


}