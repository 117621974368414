/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import GenerateSlug from '../../utils/GenerateSlug';
import PostOverlay from '../PostOverlay';
import {useAuth} from '../AuthContext';
import ProjectsService from '../../services/ProjectsService';
import PostsService from '../../services/PostsService';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MoreAction(props) {
  const { authToken } = useAuth();
  const [url, setUrl] = useState();
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);  
  const navigate = useNavigate();

  const doConfirmDelete = async (id) => {    
    setDeleteId(id);
    setConfirmDelete(true);
  };

  const doDelete = async () => {    
    
    if (deleteId > 0) {
      if (props.cardType === 'post')
        await PostsService.deletePost(deleteId);
      else 
        await ProjectsService.deleteProject(deleteId);

      setDeleteId(0);
    }
    setConfirmDelete(false);

    // get params and replace ts with the new one if ts exists
    const queryParams = new URLSearchParams(navigate.location.search)

    if (queryParams.has('ts')) {
      queryParams.delete('ts')
      navigate.replace({
        search: queryParams.toString(),
      })
    }
    // refresh page with new ts
    //history.push (history.location.search + '?ts=' + Date.now());
    navigate('/@' + authToken.username + '?ts=' + Date.now()); // timestamp to force refresh the feed


  };

  const handleConfirmCancel = async () => {    
    setConfirmDelete(false);
  };

  useEffect( () => {
    if (props.cardType === 'project') {
      // project
      setId(props.projectId);
      setUrl ('https://api.make.rs/sharer/project/' + GenerateSlug (props.projectId, props.projectName));
    }
    else {
      // post
      setId(props.postId);
      setUrl ('https://api.make.rs/sharer/post/' + props.postId);
    }
    
  }, []); 

  const OpenEditOverlay = () => {
    if (!edit){
        document.body.classList.add('modal-open');
    }
    else {
        document.body.classList.remove('modal-open');
    }

    setEdit(!edit);
    
} 

  return (
    <>
    <Menu as="div" className="relative inline-block text-left mt-4">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-800 focus:outline-none">
              <span className="sr-only">Open options</span>
              <DotsHorizontalIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute left-0 z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a href={'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url)} className={'flex items-center ' + classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','px-4 py-2 text-sm')}>Share on 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 449.956 449.956" fill="currentColor" className="h-4 w-4 mx-2 text-twitter">
	<path d="M449.956,85.657c-17.702,7.614-35.408,12.369-53.102,14.279c19.985-11.991,33.503-28.931,40.546-50.819
		c-18.281,10.847-37.787,18.268-58.532,22.267c-18.274-19.414-40.73-29.125-67.383-29.125c-25.502,0-47.246,8.992-65.24,26.98
		c-17.984,17.987-26.977,39.731-26.977,65.235c0,6.851,0.76,13.896,2.284,21.128c-37.688-1.903-73.042-11.372-106.068-28.407
		C82.46,110.158,54.433,87.46,31.403,59.101c-8.375,14.272-12.564,29.787-12.564,46.536c0,15.798,3.711,30.456,11.138,43.97
		c7.422,13.512,17.417,24.455,29.98,32.831c-14.849-0.572-28.743-4.475-41.684-11.708v1.142c0,22.271,6.995,41.824,20.983,58.674
		c13.99,16.848,31.645,27.453,52.961,31.833c-7.995,2.091-16.086,3.138-24.269,3.138c-5.33,0-11.136-0.475-17.416-1.42
		c5.9,18.459,16.75,33.633,32.546,45.535c15.799,11.896,33.691,18.028,53.677,18.418c-33.498,26.262-71.66,39.393-114.486,39.393
		c-8.186,0-15.607-0.373-22.27-1.139c42.827,27.596,90.03,41.394,141.612,41.394c32.738,0,63.478-5.181,92.21-15.557
		c28.746-10.369,53.297-24.267,73.665-41.686c20.362-17.415,37.925-37.448,52.674-60.097c14.75-22.651,25.738-46.298,32.977-70.946
		c7.23-24.653,10.848-49.344,10.848-74.092c0-5.33-0.096-9.325-0.287-11.991C421.785,120.202,437.202,104.306,449.956,85.657z"/>
</svg>Twitter</a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a href={'https://www.facebook.com/share.php?u=' + encodeURIComponent(url)} className={'flex items-center ' + classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','px-4 py-2 text-sm')}>Share on 
  <svg viewBox="0 0 512 512" className='h-4 w-4 mx-2 text-white ' xmlns="http://www.w3.org/2000/svg"><path d="m483.738281 0h-455.5c-15.597656.0078125-28.24218725 12.660156-28.238281 28.261719v455.5c.0078125 15.597656 12.660156 28.242187 28.261719 28.238281h455.476562c15.605469.003906 28.257813-12.644531 28.261719-28.25 0-.003906 0-.007812 0-.011719v-455.5c-.007812-15.597656-12.660156-28.24218725-28.261719-28.238281zm0 0" fill="#4267b2"/><path d="m353.5 512v-198h66.75l10-77.5h-76.75v-49.359375c0-22.386719 6.214844-37.640625 38.316406-37.640625h40.683594v-69.128906c-7.078125-.941406-31.363281-3.046875-59.621094-3.046875-59 0-99.378906 36-99.378906 102.140625v57.035156h-66.5v77.5h66.5v198zm0 0" fill="currentColor"/></svg>                    
                    Facebook</a>
                  )}
                </Menu.Item>
                {(authToken && authToken.username == props.username) ? 
                <>
                <Menu.Item>
                    {({ active }) => (
                      <button onClick={()=> {OpenEditOverlay();}}  className={'flex items-center ' + classNames(active ? 'bg-gray-100 text-gray-900 w-full' : 'text-gray-700','px-4 py-2 text-sm')}>Edit
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>                      
                      </button>                      
                    )}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => (
                      <button onClick={()=> {doConfirmDelete(id);}}  className={'flex items-center ' + classNames(active ? 'bg-gray-100 text-gray-900 w-full' : 'text-gray-700','px-4 py-2 text-sm')}>Delete
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>                      
                      </button>                      
                    )}
                </Menu.Item>
                </>
                : ''}

              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
    {edit ? 
      (props.cardType == 'post' ? 
      <PostOverlay id={props.postId} userId={authToken.id} setOpenOverlay={OpenEditOverlay} postType='update' mode='editPost'/> 
      :
      <PostOverlay id={props.projectId} userId={authToken.id} setOpenOverlay={OpenEditOverlay} postType='project' mode='editProject'/> 
      )
      : ''
    }

{confirmDelete? <>
  <div className="fixed z-10 inset-0 overflow-y-auto" ariaLabelledby="modal-title" role="dialog" ariaModal="true">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ariaHidden="true"></div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" ariaHidden="true">&#8203;</span>

      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start ">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">

              <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" ariaHidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>

            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                Delete alert
              </h3>
              <div className="mt-2">
                <p className="text-lg text-gray-500">
                  Are you sure you want to delete this {props.cardType}? This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 text-center">
          <button type="button" onClick={() => {doDelete();}} className="w-full sm:w-auto inline-flex justify-center font-bold border-2 border-black rounded-md shadow-red py-2 px-4 hover:bg-red-500 focus:outline-none">
            Yes, delete
          </button>
          <button type="button" onClick = {() => {handleConfirmCancel()}} className="mt-3 sm:mt-0 sm:ml-3 w-full sm:w-auto inline-flex justify-center font-bold border-2 border-black rounded-md shadow-gray py-2 px-4 hover:bg-gray-400 focus:outline-none">
            Nevermind
          </button>
        </div>
      </div>

    </div>
  </div>    
</>
: ''} 
    </>
  )
}
