import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, Link } from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService';
import {useAuth} from '../AuthContext';
import validator from 'validator';
import Loader from "react-loader-spinner";
import 'animate.css';


export default function SignUpMagic() {
    const [mounted, setMounted] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [step, setStep] = useState(1);
    const [classStep1, setClassStep1] = useState('hidden sm:flex');
    const [classStep2, setClassStep2] = useState('');
    const [saving, setSaving] = useState(false);
    const { authToken, setAuthToken } = useAuth();


    const buttonClaim = async () => {
        // reset styles
        setClassStep1("hidden sm:flex");
        setClassStep2("");
        setError('');
        // check if username is available
        let ret = 'NOK';
        if (username.trim().length > 0 && validator.isAlphanumeric(username)) 
            ret = await AuthenticationService.checkUsername(username.trim());
        
        if (ret === 'OK')
        {
            //setClassStep1("");
            setClassStep2("animate__animated animate__fadeIn animate__fast");
            setStep(2); // go to step 2
        }
        else 
        {
            //setClassStep2("");
            setClassStep1("hidden sm:flex animate__animated animate__shakeX animate__fast" );
            //setTimeout(() => setClassStep1("animate__animated animate__shakeX animate__fast" ), 0); // hack to force react process the state change
            setError('Username');
        }
    }

    const buttonFinish = async () => {
        // setSaving for loader
        setSaving(true);
        // reset styles
        setClassStep1("");
        setClassStep2("");
        setError('');
        // check if email is available
        let ret = 'NOK';
        if (email.length > 0 && validator.isEmail(email)) 
            ret = await AuthenticationService.checkEmail(email);

        if (ret === 'OK')
        {
            //setClassStep2("");
            // now register the user
            let userToken  = await AuthenticationService.signUp(email, username);            
            userToken.firstSignIn = true;  // add a property to mark it was registered recently to avoid the redirection from home
            
            // authenticate user globally
            setAuthToken(userToken);
            setLoggedIn(true);
            //console.log('set logged in')
            //
            //setStep(1); 
        }
        else 
        {

            //setClassStep1(""); // force a refresh in case it tries again with a taken username
            setTimeout(() => setClassStep2("animate__animated animate__shakeX animate__fast"), 0);
            setError('Email');
        }

        //
        setSaving(false);

    }

    useEffect(  () => {
        setMounted(true);
    }, []);

    if (loggedIn) {
        return <Navigate to={"/welcome"} />;
    }

    if (mounted) 
    return (
        <>
        <div className='text-center text-xl font-bold mt-2 sm:hidden'>👉 <Link to='/sign-up' className='underline text-blue-600 hover:text-blue-800'>Sign up</Link></div>
        <div className={'container mx-auto  justify-center mt-6 ' + (step === 2 ? 'hidden' : classStep1)}>    
            <span className="text-lg sm:text-2xl py-2 mr-2">👉 make.rs/@ </span>
            <input id="username" name="username" onChange={e => { setUsername(e.target.value);}} className="font-bold border-2 border-black rounded-md py-2 px-4 focus:outline-none"/>
            <button onClick={buttonClaim} className="text-center font-bold border-2 border-black rounded-md shadow-emerald py-2 px-4 ml-2 hover:bg-emerald focus:outline-none" >CLAIM</button>

        </div>
        <div className={'container mx-auto flex justify-center mt-6 ' + (step === 1 ? 'hidden' : classStep2)}>            
            <span className="text-2xl py-2 mr-2">Your email </span>
            <input id="email" name="email" onChange={e => { setEmail(e.target.value);}} className="font-bold border-2 border-black rounded-md py-2 px-4 focus:outline-none"/>
            {saving ?
            <Loader type="ThreeDots" color="#000000" />
            :
            <button onClick={buttonFinish} className="text-center font-bold border-2 border-black rounded-md shadow-emerald py-2 px-4 ml-2 hover:bg-emerald focus:outline-none" >Finish</button>
            }
            
        </div>
        {error === 'Username' ? <p className='text-center font-bold text-red-600'>This username is invalid or in use.</p> : ''}        
        {error === 'Email' ? <p className='text-center font-bold text-red-600'>This email is invalid or in use.</p> : ''}        

    </>
    );
}