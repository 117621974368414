import React, { useState, useEffect } from 'react';
import {useAuth} from '../AuthContext';
import NotificationCard from './NotificationCard';
import NotificationsService from '../../services/NotificationsService';


export default function NotificationsOverlay(props)  {
    const [notifications, setNotifications] = useState([]);
    const { authToken } = useAuth();
    const [mounted, setMounted] = useState(false);

    useEffect(  () => {
        const loadData = async() => {
            // get new notifications        
            const ret = await NotificationsService.getNotifications(authToken.accessToken.token);
            if (ret) {
                setNotifications(ret);
            }
            // mark as seen the rest; no need to await        
            NotificationsService.seenNotifications(authToken.accessToken.token);

            setMounted(true);
        }
        loadData();

    }, []);

    if (!mounted)     
        return (null);
    else
    return (
    <div className="fixed z-10 inset-0 modal-open" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="block justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

        <div className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity" aria-hidden="true"></div>


        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto max-h-screen shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-2/3">
           
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start ">
                    
                    <button onClick={() => {props.setOpenOverlay()}} className='absolute right-0 top-0 mt-2 mr-2 text-gray-600 hover:text-black focus:outline-none'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                    <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">                    
                    
                        <span className={'text-lg font-bold bg-yellow text-gray-900 rounded-md px-2 py-2' }>🔔 Notifications</span>

                        <div className="mt-10">

                        {notifications.length===0 ? <p className='mb-10'>You do not have any notification at the moment. Post an update or a project to get some reactions 🥳</p>: '' }
                        {notifications.map(function(notif, index){
                            return (
                                <NotificationCard key={notif.id} notification={notif}/>
                            )
                            }
                        )}  
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    </div>    
    );
}

