import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import FeedsService from '../../services/FeedsService';
var dayjs = require ('dayjs');
var utc = require('dayjs/plugin/utc')
var relativeTime = require('dayjs/plugin/relativeTime')
var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(isoWeek)



export default function HeatMapCalendar(props) {
    const location = useLocation();
    const [days, setDays] = useState([]);
    const [contributions, setContributions] = useState([]);
    const [contributionsTotal, setContributionsTotal] = useState(0);
    const [mounted, setMounted] = useState(false);

    useEffect(  () => {

        const loadData = async () => {
            // get contributions from API
            const contri = await FeedsService.getContributions(props.userId);
            setContributionsTotal(contri.length)
            
            // get last 30 days including end of this week
            const today = dayjs();
            const endDay = today.endOf('isoWeek');
            const startDay = endDay.subtract(4, 'week').add(1, 'days');

            // generate days array
            let daysArray = [];
            let contributionsArray = [];
            for (var i=0; i<28; i++){
                // push day
                daysArray.push (startDay.add(i, 'days').format('YYYY-MM-DD'));
                // push contribution
                // check if contri exists if not put 0
                if (contri && contri.length > 0)
                {
                    const found = contri.find(x => x.moment == startDay.add(i, 'days').format('YYYY-MM-DD'));
                    if (found) {
                        contributionsArray.push(found.total);
                    }
                    else {
                        contributionsArray.push(0);
                    }
                }
                else {
                    contributionsArray.push (0);
                }

            }        
            setDays(daysArray);
            setContributions(contributionsArray);

            setMounted(true);
        } 
        loadData();
        
      }, [location.search]);
       
    
if (!mounted) return ('');
else
return (
    <div className='hidden md:block'>
        <p className="text-black text-2xl font-bold mb-1 ">🗓️ Activity log</p>
        <p className='text-gray-500 text-sm mb-6'>{contributionsTotal} posts last 4 weeks</p>
        <div className='w-52 grid grid-cols-7'>
            <div className='w-4 text-center mb-4'>M</div>
            <div className='w-4 text-center mb-4'>T</div>
            <div className='w-4 text-center mb-4'>W</div>
            <div className='w-4 text-center mb-4'>T</div>
            <div className='w-4 text-center mb-4'>F</div>
            <div className='w-4 text-center mb-4'>S</div>
            <div className='w-4 text-center mb-4'>S</div>

            {days.map(function(day, index) {
                return <div className='h-4 w-4 flex justify-center items-center' key={index}>
                    {                    
                    contributions[index] == 0 ? 
                    (dayjs(day).diff(dayjs()) > 0 ? <div></div>: <div className="rounded-full h-1 w-1 bg-gray-300 "></div> )
                    
                    : 
                    <div className="rounded-full h-3 w-3 bg-black "></div>
                    }
                </div>
            })}

        </div>
    </div>
);


}