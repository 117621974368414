import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import UsersService from '../../services/UsersService';


export default function UserList(props)  {

    const [users, setUsers] = useState(null);

    useEffect( () => {
      const loadData = async() => {
        const ret  = await UsersService.getUsers();
        if (ret){
            if (ret.length > 0)
                setUsers(ret);
            else
                setUsers(null); 
        }
      }
      loadData();
      
  }, []); // second param to avoid the execution multiple times

    return (
        <>
          {users ? (
            <div>
              <div className='mx-auto'>        
                  <p className="text-center text-black text-4xl sm:text-6xl font-bold">Makers Directory</p>
                  <p>Count = {users.length}</p>
              </div>

            {users.map(function(user, index){
              return (
                  <div key={index} className='flex'>
                    <img key={index} src={user.avatar && user.avatar != 'undefined'? 'https://s3.eu-west-3.amazonaws.com/make.rs/avatars/' + user.avatar : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} className='w-20 h-20 rounded-full flex-none'  />
                    <Link to={'/@' + user.username} className='underline text-blue-600 hover:text-blue-800'>@{user.username}</Link>
                  </div>
                )
              }
            )}
          </div>
          ) : (null)}
          </>
        ) 
}

