import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from '../AuthContext';
import CommentsService from '../../services/CommentsService';
import validator from 'validator';

export default function Reply(props) {
    const { authToken } = useAuth();
    const [reply, setReply] = useState(false);
    const [body, setBody] = useState('');
    const [hasError, setHasError]= useState(false);

    useEffect( () => {
    }, []);

    const handleReplyClick = async(event) => {
        event.preventDefault();
        setReply(true);
    }

    const handleCancelClick = async(event) => {
        event.preventDefault();
        setReply(false);
    }

    const handleSubmitClick = async(event) => {
        let errors = false;

        event.preventDefault();

        if (body===null || validator.isEmpty(body.trim())) {
          setHasError(true);
          errors = true;
        }

        if (!errors) {

          props.incrementCommentsCount();
          if (props.cardType === 'project')
            CommentsService.newProjectComment(authToken.accessToken.token, props.id, props.parentId, body)
            .then(
              async () => {
                  // refresh comments
                  await props.refreshComments(props.id);
                  // close reply 
                  setReply(false);
                  setHasError(false);
                  setBody('');
              },
              error => { console.log("Update error = { " + error.toString() + " }");
              }        
            );
          else if (props.cardType === 'post')
          CommentsService.newPostComment(authToken.accessToken.token, props.id, props.parentId, body)
          .then(
            async () => {
                // refresh comments
                await props.refreshComments(props.id);
                // close reply 
                setReply(false);
                setHasError(false);
                setBody('');
            },
            error => {
              console.log("Update error = { " + error.toString() + " }");
            }        
          );

        }
    }

  if (!authToken)
  return (
    <p><Link to='/sign-in' className='underline text-blue-600 hover:text-blue-800'>Sign in</Link> to {(props.text == 'Add comment' || props.text == 'Be the first one to comment')? 'comment' : props.text}.</p>
  );    
  else 
  return (
        <div>
            <button type='button' onClick={handleReplyClick} className=' text-blue-600 hover:text-blue-800 focus:outline-none'>{props.text}</button>

            <div className={'w-96 ' + (reply ? 'block': 'hidden') }>
            <textarea id="body" onChange={e => { setBody(e.target.value);}} className={'border-2 rounded-md py-2 mb-2 w-full focus:outline-none ' + (hasError ? 'border-red-500': 'border-black')} value={body}></textarea>
            <button type='button' onClick={handleSubmitClick} className='font-bold text-center text-sm border-2 border-black rounded-md shadow-emerald py-1 px-1  hover:bg-emerald focus:outline-none'>Send</button>
            <button type='button' onClick={handleCancelClick} className='font-bold text-center text-sm border-2 border-black rounded-md shadow-gray py-1 px-1 ml-5 hover:bg-gray-400 focus:outline-none'>Cancel</button>
            </div>
        </div>
  );


}

