import React, {useState, useEffect} from 'react';
import ByUser from '../Users/ByUser';
import Reply from './Reply';
import Linkify from 'react-linkify';
var dayjs = require ('dayjs');
var utc = require('dayjs/plugin/utc')
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(relativeTime)

export default function CommentCard(props) {
    const [displayDate, setDisplayDate] = useState();

    useEffect( () => {
        //console.log(props.comments)
        var postDate = dayjs(props.createdAt).utc();
        var isToday = false;
        var format = 'D MMM YYYY';
        if (postDate.isSame(dayjs().utc(), 'day')) {
            isToday = true;
        }
        // set post date format depending if it is the same year or not
        if (postDate.isSame(dayjs().utc(), 'year')) {
            format = 'D MMM';
        }
        

        if (isToday) {
            setDisplayDate(postDate.fromNow())
        }
        else {
            setDisplayDate(postDate.format(format))
        }


    }, []);

  return (
    <>
        <div className={' bg-white ml-4 mt-4 '}>
            <div className='flex items-center'>
            <ByUser username={props.username} avatar={props.avatar}></ByUser>     
            {props.isMaker ? <span className='text-sm rounded-full border-none bg-yellowLight  text-gray-500 ml-2 px-1'>maker</span>:''}
            <span className='text-gray-300 text-sm ml-2'>{displayDate}</span>
            </div>
            <div className='text-gray-700 whitespace-pre-line mb-4'>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" className='underline text-blue-600 hover:text-blue-800 focus:outline-none' href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}>{props.body}</Linkify>                
            </div>
            <Reply incrementCommentsCount={props.incrementCommentsCount} id={props.id} parentId={props.commentId} refreshComments={props.refreshComments} text="reply" cardType={props.cardType}/>
        </div>

        
        <ul>
        {props.comments.filter(x=>x.parentId == props.commentId).map(function(comment, index){
            return (
            <li key={index}>
               <CommentCard incrementCommentsCount={props.incrementCommentsCount} commentId={comment.id} refreshComments={props.refreshComments} isMaker={(comment.userId === props.userId)}  userId={props.userId} id={props.id} body={comment.body} createdAt={comment.createdAt} username={comment.username} avatar={comment.avatar} comments={props.comments} cardType={props.cardType}/>
            </li>
            )
        }
        )}
        </ul>
  </>
  );


}

