import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import ProjectsService from '../../services/ProjectsService';
import GenerateSlug from '../../utils/GenerateSlug';
import Loader from "react-loader-spinner";


export default function NewProjects()  {

    const [projects, setProjects] = useState();
    const [mounted, setMounted] = useState (false);

    useEffect( () => {
      const loadData = async() => {
        const ret  = await ProjectsService.getNewProjects();
        if (ret){
            if (ret.length > 0)
              setProjects(ret);
            else
              setProjects(null); 

        }
        setMounted(true);
      }

      loadData();
  }, []); // second param to avoid the execution multiple times

  if (!mounted) 
  {/*  if not mounted show display the component title */}
  if (!mounted)   
    return (
      <div className='w-full mt-20'>
        <div className='hidden  md:block '>
        <p className="text-black text-2xl font-bold mb-6 ">💎 New projects</p>
        <div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>
       </div>
      </div>
    );


  else
    return (
        <div className='w-full mt-20'>
          {projects ? (
            <div className='hidden  md:block'>
            <p className="text-black text-2xl font-bold mb-6 ">💎 New projects</p>

            {projects.slice(0, 20).map(function(project, index){
              return (
                  <div key={project.id} className='flex mb-2 items-center'>
                    <div className='ml-1'>
                    <Link to={'/project/' + GenerateSlug (project.id, project.name)} className='underline bg-yellowLight text-blue-600 hover:text-blue-800'>{project.name}</Link>
                    </div>
                  </div>
                )
              }
            )}
          </div>
          ) : (null)}
          </div>
        ) 
}

